import React from 'react';
import AddItem from "components/AddItem";
import {url} from "helpers";

export default function Leadership(props){
    const config ={
        itemType:"leadership",
        columns:[
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Leadership Title", 
                placeholder: "Enter Title", 
                offset: 1, 
                col:5
            },
        
            {
                type: "select-filter",
                id: "member_type", 
                name: "member_type", 
                title: "Leadership Type", 
                options: [], 
                placeholder: "Choose Type", 
                valueField:"member_type.id",
                optionsURL:url.membertype,
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Type", 
                // onCreateOption: newRegionModal, 
                append: document.body, 
                offset: 1, 
                col:5
            }
        ]
    }

    return <AddItem {...props} config={config} />
}