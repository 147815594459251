import MetaTags from 'react-meta-tags';
import React, { useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"
// Redux
import { Link, useHistory } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { ACCESS_TOKEN} from '../../constants';
import { requests, setAuthorization, url, utils } from 'helpers';

export default function Login(props){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");
    const [failed, setFailed] = useState(null);
    
    
	const history = useHistory()
	const goDashboard = (user) => {
        setLoading(true);
        requests.get(url.getURL("member", {type:"detail", item:{id:user.member_id}}))
        .then(member=>{
            // save member profile
            const loggedUser = utils.getUser();
            utils.setUser({
                ...loggedUser,
                member,
            })
        }).catch(error=>{

        }).finally(()=>{
            setLoading(false);
            history.push(url.routes.dashboard)
        })
	}

    const handleSubmit = async(e) => {
        e.preventDefault();
        setFailed(null)
        setLoading(true)
        try{
            const res = await requests.post(url.login, {username, password});
            localStorage.setItem(ACCESS_TOKEN, res.key);
			getUserDetails(res.key)
        }catch(error){
			setAlert(error.message);
            setFailed(true)
        }
        setLoading(false)
	}

    const getUserDetails = async(token) => {
        try{
            setAuthorization(token)
            const res = await requests.get(url.authuser)
            utils.setUser({
                ...res,
                token,
                fullName: res.first_name + " " + res.last_name,
            })
            goDashboard(res);
        }catch(error){
            setAlert("Failed to fetch user details"+". "+JSON.stringify(error.message));
            setFailed(true);
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
        <MetaTags>
            <title>Login | Tayomi</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
            </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
            <Container>
            <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                    <Row>
                        <Col xs={12}>
                            <div className="text-primary p-4 text-center">
                                <h5 className="text-primary">Welcome Back !</h5>
                                <p>Sign in to continue to TAYOMI</p>
                            </div>
                        </Col>
                        {/* <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                        </Col> */}
                    </Row>
                    </div>
                    <CardBody className="pt-3">
                    {/* <div>
                        <Link to="/" className="auth-logo-light">
                            <div className="avatar-md profile-user-wid mb-4">
                                <span className="avatar-title rounded-circle bg-light">
                                    <img src={logo} alt="" className="rounded-circle" height="34" />
                                </span>
                            </div>
                        </Link>
                    </div> */}
                    <div className="p-2">
                        <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                            {failed &&
                                <Alert color="danger">{alert}</Alert>
                            }
                            { loading &&
                                <div className="mb-3 text-center">
                                    <Spinner className="ms-2" color="primary" />
                                </div>
                            }
                            <div className="mb-3">
                                <AvField 
                                    name="username" 
                                    label="Username" 
                                    value={username} 
                                    onChange={(e) => setUsername(e.target.value)} 
                                    className="form-control" 
                                    placeholder="Enter username" 
                                    type="text" 
                                    required 
                                    // errorMessage={errorMessage}
                                />
                            </div>

                            <div className="mb-3">
                                <AvField name="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" required placeholder="Enter Password" />
                            </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="customControlInline" />
                                <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                            </div>

                            <div className="mt-3 d-grid">
                                <button disabled={loading} className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                            </div>

                            {/* <div className="mt-4 text-center">
                                <Link to="/forgot-password" className="text-muted">
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                                </Link>
                            </div> */}
                        </AvForm>
                    </div>
                    </CardBody>
                </Card>
                <div className="mt-5 text-center">
                    <p>
                    Don&#39;t have an account ?{" "} <Link to="register" className="fw-medium text-primary">{" "} Signup now{" "}</Link>{" "}
                    </p>
                    <p>
                    © {new Date().getFullYear()} TAYOMI
                    </p>
                </div>
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    )
}
