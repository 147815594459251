import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils, url} from "helpers";
import { CONTENT_IDS } from "../../constants"

export default function Groups(props){
    const loggedUser = utils.getUser();
    const isStaff = loggedUser?.is_staff;
    const isCLPDField=({ item, values }) => {
        return CONTENT_IDS.member_type[`${values?.member_type}`]?.name === "CLPD"
      }

    const advancedSearch = {
        itemType:"certificate",
        // permissionsTitle:"Share Request",
        columns:[
            {
                type: "select-filter",
                id: "member__id", 
                name: "member__id", 
                title: "Member", 
                options: [], 
                placeholder: "Choose Member", 
                valueField:"member.id",
                optionsURL:url.member,
                getLabel:(item)=>`${item.user?.first_name} ${item.user?.last_name} (${item.user?.username})`,
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Type Member", 
                // onCreateOption: newRegionModal, 
                append: document.body, 
                offset: 1, 
                col:5
            },
            {
                type: "select-filter",
                required: true,
                id: "member__member_type__id",
                name: "member__member_type__id",
                title: "Member/Leader Type",
                optionsURL: url.membertype,
                valueField: "member_type.id",
                _setValue: item => {
                  return { value: item?.member_type?.id, label: item?.member_type?.name }
                },
                options: [],
                placeholder: "Choose Member Type",
                append: document.body,
                offset: 1, 
                col:5,
                getOptionsParams: state => ({ search: state.member_type || "" })
              },
              {
                type: "select-filter",
                id: "member__branch__id",
                name: "member__branch__id",
                valueField: "branch.id",
                title: "Branch",
                optionsURL: url.branch,
                options: [],
                placeholder: "Choose Branch",
                append: document.body,
                offset: 1, 
                col:5,
                canShow: (params)=>!isCLPDField(params),
              },     

            isStaff &&  {
                type: "select", 
                id: "status", 
                name: "status", 
                title: "Request Status",  
                options:  [
                        {label: "Pending", value: "pending"},
                        {label: "Rejected", value: "rejected"},
                        {label: "Processed", value: "processed"},
                ], 
                placeholder: "Choose Status",
                formatBeforeSubmit:(v)=>v.value,
                append: document.body, 
                offset: 1, 
                col:5,
                required: true,
            },
        ].filter(d=>d)
    }

    const columns = [
        {
            label: "Member ID",
            field: "member",
            formatValue:(v)=>v.membership_no||v.id,
            sort: "asc",
            width: 250,
        },
        {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 250,
        },
        {
            label: "Branch",
            field: "member.branch.name",
            sort: "asc",
            width: 250,
        },
        {
            label: "Request On",
            field: "created",
            formatValue:(v)=>utils.formatDate(v, "lll"),
            sort: "asc",
            width: 250,
        },
    
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 250,
        },
        {
            label: "Actions",
            field: "action",
            sort: "asc",
            width: 100,
            // canEdit:true,
            // canDelete:true,
        },
    ];

    return<React.Fragment>
            <Table 
                    {...props}
                    advancedSearch={advancedSearch}
                    columns={columns}
                    itemType = "certificate"
                    itemType_verbose="Certificate"
            />
            </React.Fragment>
    
}
