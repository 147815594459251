import React from 'react';
import AddItem from "components/AddItem";
import {url} from "helpers";

export default function Zonal(props){
    const config ={
        itemType:"zonal",
        columns:[
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Zonal Name", 
                placeholder: "Enter Name", 
                offset: 1, 
                col:5
             },
            ]
    }

    return <AddItem {...props} config={config} />
}