import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils} from "helpers"
export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Leadership Type",
        field: "member_type.name",
        sort: "asc",
        width: 250,
    },
    // {
    //     label: "Added On",
    //     field: "created",
    //     formatValue:(v)=>utils.formatDate(v),
    //     sort: "asc",
    //     width: 250,
    // },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Leaderships(props){
    return <Table 
            columns={columns}
            itemType = "leadership"
            filter={Object}
            itemType_verbose="leadership title"
    />
    
}
