import React, { useEffect, useState } from "react"
import Table from "components/Table";
import { Badge } from "reactstrap";
import { utils, url } from "helpers";
import { Link } from "react-router-dom";

export const columns = [
    {
        label: "Created On",
        field: "created",
        formatValue: (v) => utils.formatDate(v),
        sort: "asc",
        width: 250,
    },
    {
        label: "Sender",
        field: "from_account.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Recipient",
        field: "to_account.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Amount Paid (TZS)",
        field: "amount_paid",
        sort: "asc",
        formatValue:(v, item)=>{
            return <Link to={`${url.routes.payments}?transaction__id=${item.id}`}>{utils.formatNumber(v)}</Link>
        },
        width: 250,
    },
    {
        label: "Amount Due (TZS)",
        field: "amount_due",
        sort: "asc",
        formatValue:(v, item)=>{
            return <Link to={`${url.routes.payments}?transaction__id=${item.id}`}>{utils.formatNumber(v)}</Link>
        },
        width: 250,
    },
    {
        label: "Total Amount (TZS)",
        field: "amount",
        sort: "asc",
        formatValue:(v, item)=>{
            return <Link to={`${url.routes.payments}?transaction__id=${item.id}`}>{utils.formatNumber(v)}</Link>
        },
        width: 250,
    },


    {
        label: "Category",
        field: "transaction_category.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Paid",
        field: "is_paid",
        formatValue: (is_paid) => (<Badge
                className={"font-size-11 badge-soft-" + (is_paid ? "success" : "warning")}
                color={is_paid ? "success" : "warning"} pill>
                {is_paid ? "Yes" : "No"}
            </Badge>
        ),
        sort: "asc",
        width: 250,
    },
    {
        label: "Due Date",
        field: "due_date",
        formatValue: (v) => utils.formatDate(v),
        sort: "asc",
        width: 250,
    },
    {
        label: "Attachments",
        field: "attachments",
        formatValue: (v) => {
            return v.length>0?v.length:"No"
        },
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Transactions(props) {
    const loggedUser = utils.getUser()
    const isStaff = loggedUser?.is_staff;
    const nonModal = true;
    const displayElement = true;

    const advancedSearch ={
        itemType:"payments.transaction",
        itemType_verbose:"Transaction",
        permissionsTitle:"Share Transaction",
        attachmentQuery:{advanced_search:"transactions__id:{id}"},
        columns: [
          {
            type: displayElement ? "select-filter" : "select-modal-filter",
            id: "from_account__id",
            name: "from_account__id",
            title: "From Account",
            // value: dataJson.from_account,
            options: [],
            optionsURL: url.payments.account,
            valueField: "from_account.id",
            placeholder: "Choose Account",
            noOptionsMessage: "Search to add new Account",
            append: document.body,
            offset: 1,
            col: nonModal ? 5 : 10,
          },
          {
            type: displayElement ? "select-filter" : "select-modal-filter",
            id: "to_account__id",
            name: "to_account__id",
            title: "To Account",
            // value: dataJson.to_account,
            options: [],
            optionsURL: url.payments.account,
            valueField: "to_account.id",
            placeholder: "Choose Account",
            noOptionsMessage: "Search to add new Account",
            append: document.body,
            offset: 1,
            col: nonModal ? 5 : 10,
          },
          {
            type: "datetime",
            id: "due_date__gte",
            name: "due_date__gte",
            title: "Due Date From",
            // value: dataJson.due_date,
            placeholder: "Due Date From",
            formatBeforeSubmit:(v)=>moment(v).format(),
            altFormat: "F j, Y",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            offset: 1,
            col: nonModal ? 5 : 10,
          },
          {
            type: "datetime",
            id: "due_date__lte",
            name: "due_date__lte",
            title: "Due Date Until",
            // value: dataJson.due_date,
            placeholder: "Enter Due Date",
            formatBeforeSubmit:(v)=>moment(v).format(),
            altFormat: "F j, Y",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            offset: 1,
            col: nonModal ? 5 : 10,
          },

          {
            type: displayElement ? "select-filter" : "select-modal-filter",
            id: "transaction_category__id",
            name: "transaction_category__id",
            title: "Transaction Category",
            optionsURL: url.payments.transactioncategory,
            valueField:"transaction_category.id",
            // value: dataJson.transaction_category,
            options: [],
            placeholder: "Choose Category",
            noOptionsMessage: "Search to add new Transaction Category",
            append: document.body,
            offset: 1,
            col: nonModal ? 5 : 10,
          },
          {
            type: "boolean",
            id: "is_paid",
            name: "is_paid",
            title: "Is Paid",
            // value: dataJson.is_paid,
            placeholder: "Tick if Paid",
            offset: 1,
            col: nonModal ? 5 : 10,
          },
        ].filter(v=>v)  
    }

    return <Table
        advancedSearch={advancedSearch}
        columns={columns}
        itemType="payments.transaction"
        itemType_verbose="Transaction"
    />
}