import React from 'react';
import AddItem from "components/AddItem";
import { url } from "helpers";

export default function Group(props) {
    const config = {
        itemType: "group",
        title: "User Groups",
        permissionsTitle: "Add Users to Group",
        hideGroups: true,
        subtitle: "Groups helps to easily manage permissions and site access",
        canEdit:({item, values})=>{
            if(item){
               return item?._permissions?.includes("change_group")
            }else{
                return true
            }
        },
        canDelete:({item, values})=>{
            if(item){
               return item?._permissions?.includes("delete_group")
            }else{
                return true
            }
        },
        columns: [
            {
                type: "text",
                id: "name",
                name: "name",
                title: "Group Name",
                placeholder: "Enter Name",
                offset: 1,
                col: 5
            },
            {
                type: "select-array",
                id: "permissions",
                name: "permissions",
                title: "Permissions",
                options: [],
                placeholder: "Choose Permissions",
                optionsURL: url.permission,
                setValue: (item) => item?.permissions?.map(p => ({ value: p.id, label: p.name })),
                formatValueBeforeSubmit: (d, item) => {
                    const allIds = item?.permissions.map(i => i.id) || [];
                    const newIds = d?.map(d => d.value) || [];
                    const remove = _.difference(allIds, newIds)
                    return { "add": newIds, remove:item?.id?remove:undefined }
                },
                append: document.body,
                offset: 1,
                col: 5
            }
        ]
    }

    return <AddItem {...props} config={config} />
}