import React from "react"
import { Row, Button, Modal, ModalHeader, } from "reactstrap"
import delete_icon from "assets/icons/delete.png";

export const DeleteItem = (props) => {
    return(
        <Modal isOpen={props.displayConfirmation} role="dialog" autoFocus={true} centered={true} tabIndex="-1" data-toggle="modal" toggle={() => {props.setDisplayConfirmation(!props.displayConfirmation)}}>
            <div className="modal-content">
                <div className="modal-header border-bottom-0">
                    <ModalHeader toggle={() => { props.setDisplayConfirmation(!props.displayConfirmation) }}></ModalHeader>
                </div>
                <div className="modal-body pt-0">
                    <div className="text-center mb-2">
                        <div className="avatar-md mx-auto mb-4 pt-0">
                            <div className="avatar-title bg-light rounded-circle text-primary h1">
                                {/* <i className="mdi mdi-email-open"></i> */}
                                <img src={delete_icon} width="40" alt="Confirm Delete" />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <h5 className="text-primary mb-3">Confirm to remove {props.deleteData} !</h5>
                                <Row className="mb-3">
                                    <div className="col-6 col-md-6 col-lg-6 text-end">
                                        <Button color="danger" type="button" id="confirm" className="btn-rounded" onClick={() => props.removeData()}><i className="bx bx-check"></i> Confirm</Button>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6 text-start">
                                        <Button color="primary" type="button" id="confirm" className="btn-rounded" onClick={() => props.setDisplayConfirmation(false)}><i className="bx bx-x"></i> Cancel</Button>
                                    </div>
                                </Row>
                                <p className="text-muted font-size-14 mb-4">The item wil be removed permanently.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}