import React, { useEffect, useState } from "react"
import Table from "components/Table";

export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Code",
        field: "code",
        sort: "asc",
        width: 250,
    },
    {
        label: "Zonal",
        field: "zonal.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Regions(props){
    return <Table 
            columns={columns}
            itemType = "region"
            itemType_verbose="Region"
    /> 
}