import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import {utils} from "helpers"

const TopCities = (props) => {
    const [topContributors, setTopContributors] = useState([{from_account__name: "Highest", value: 0}]);
    const [highest, setHighest] = useState({from_account__name: "Highest", value: 0});
    const [revenue, setRevenue] = useState(1);

    useEffect(() => {
        const array = props.dashboard?.payments?.top || [{from_account__name: "Highest", value: 0}];
        setTopContributors(array.sort((a,b) => (a.total < b.total) ? 1 : ((b.total < a.total) ? -1 : 0)));
        setHighest(array.sort((a,b) => (a.total < b.total) ? 1 : ((b.total < a.total) ? -1 : 0))[0]);
    }, [props.dashboard?.payments?.top]);

    useEffect(() => {
        setRevenue(props.dashboard?.payments?.total);
    }, [props.dashboard?.payments?.total]);

    const progressColor = (index) => {
        switch (index) {
            case 0:
                return "success"
            case 1:
                return "info"
            case 0:
                return "primary"
            case 0:
                return "warning"
            default:
                return "danger"
        }
    }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-3">Top Contributors</CardTitle>
          <div className="text-center">
            <div className="mb-3">
              <i className="bx bx-map-pin display-5"/>
            </div>
            <h3>{utils.formatMoney(highest?.total || 0)}</h3>
            <p>{highest?.from_account__name}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {topContributors.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{item?.from_account__name}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{utils.formatMoney(item?.total || 0)}</h5>
                  </td>
                  <td>
                    <Progress value={Math.round((Math.round(item?.total || 0)/Math.round(revenue || 1))*100) + 5} color={progressColor(index)} className="bg-transparent progress-sm" size="sm" />
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
