import React, { useEffect, useState } from "react"
import { CardTitle, Media, TabPane, Spinner, } from "reactstrap";
import {Link} from "react-router-dom";
import { requests, utils, url } from "helpers";

const UserInfo = (props) => {

    const [loading, setLoading] = useState(false);
    const [loggedUser, setLoggedUser] = useState(utils.getUser());

    useEffect(() => {
        // setLoading(true);
        requests.get(url.authuser)
        .then(data => {
            setLoggedUser({...loggedUser, ...data});
            utils.setUser({...loggedUser, ...data});
        }).catch(error=>{
            alert("Error getting user info"+". "+error.message)
        }).finally(()=>setLoading(false))
    }, []);

    const fields = [
        { name:"Full Name", value: loggedUser.first_name + " " + loggedUser.last_name, 
        action:{
            title:"Edit", link:url.routes.get("user", loggedUser),
        } },
        { name:"Email", value: loggedUser.email },
        { name:"Username", value: loggedUser.username }
    ];
    return (
        <TabPane tabId="UserInfo">
            <CardTitle className="mb-5 h4">Personal Details</CardTitle>
                {loading && (<div className="mb-3 text-center"><Spinner className="ms-2" color="primary" /></div>)}
                {!loading && fields.map((field, index)=><Media key={index} className="faq-box mb-4">
                    {false && <div className="faq-icon me-3">
                    <i className="bx bx-help-circle font-size-20 text-success" />
                    </div>}
                    <Media body>
                        <h5 className="font-size-15">
                            {field.name}
                        </h5>
                        <p className="text-muted">
                            {field.value} {!!field.action && <Link to={field.action.link}>{field.action.title}</Link>}
                        </p>
                    </Media>
              </Media>)}
        </TabPane>
    )
}

export default UserInfo;