import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils} from "helpers";

export const columns = [
    {
        label: "Account Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Account Holder",
        field: "account_object",
        sort: "asc",
        width: 50,
    },
    {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 250,
    },
    {
        label: "Amount Paid (TZS)",
        field: "amount_paid",
        formatValue:utils.formatNumber,
        sort: "asc",
        width: 50,
    },
    {
        label: "Amount Due (TZS)",
        field: "amount_due",
        formatValue:utils.formatNumber,
        sort: "asc",
        width: 50,
    },

    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Grades(props){
    return <Table 
            columns={columns}
            itemType = "payments.account"
            itemType_verbose="Account"
    /> 
}