import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils} from "helpers";

export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Amount",
        field: "amount",
        sort: "asc",
        formatValue:utils.formatNumber,
        width: 250,
    },
    {
        label: "Source Accounts",
        field: "source_accounts",
        formatValue:(v)=>v?.map(g=>g.name).join(", "),
        sort: "asc",
        width: 250,
    },
    {
        label: "Collection Account",
        field: "account.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Groups",
        field: "groups",
        formatValue:(v)=>v.map(g=>g.name).join(", "),
        sort: "asc",
        width: 250,
    },

    {
        label: "Member Type",
        field: "member_type.name",
        sort: "asc",
        width: 250,
    },
    
    {
        label: "Branch Type",
        field: "branch_type.name",
        sort: "asc",
        width: 250,
    },

    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Grades(props){
    return <Table 
            columns={columns}
            itemType = "payments.fee"
            itemType_verbose="Fee"
    /> 
}