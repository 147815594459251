import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils, url} from "helpers";
import moment from "moment";

export default function Tasks(props){
    const loggedUser = utils.getUser()
    const columns = [
        {
            label: "Task ID",
            field: "id",
            sort: "asc",
            width: 250,
        },
        {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 250,
        },
        {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 250,
        },
        {
            label: "Category",
            field: "task_category.name",
            sort: "asc",
            width: 250,
        },
        {
            label: "Created By",
            field: "user",
            formatValue:(user)=>`${user?.first_name} ${user?.last_name}`,
            sort: "asc",
            width: 250,
        },
    
        {
            label: "Created At",
            field: "created",
            formatValue:(v)=>utils.formatDate(v, "lll"),
            sort: "asc",
            width: 250,
        },
    
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 250,
        },
        {
            label: "Actions",
            field: "action",
            sort: "asc",
            width: 100,
            // canEdit:true,
            // canDelete:true,
        },
    ];

    const advancedSearch = {
        itemType: "todo.task",
        itemType_verbose: "Task",
        permissionsTitle: <><i className="fa fa-users"></i> Task Assignment</>,
        // attachmentQuery:{advanced_search:"tasks__id:{id}"},
        // hideGroups:true,
        columns: [
            {
                type: "text",
                id: "name",
                name: "name",
                title: "Task Name",
                placeholder: "Enter Name",
                offset: 1,
                col: 5
            },
            {
                type: "select-filter",
                id: "task_category__id",
                name: "task_category__id",
                title: "Task Category",
                options: [],
                placeholder: "Choose Category",
                valueField: "task_category.id",
                optionsURL: url.todo.taskcategory,
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Category",
                // onCreateOption: newRegionModal, 
                append: document.body,
                offset: 1,
                col: 5
            },

            {
                type: "select-filter",
                id: "user__id", name: "user__id",
                title: "Task Created By",
                value: loggedUser?.id,
                options: [],
                // optionsURL:url.user,
                // placeholder: "Choose User", 
                valueField: "user.id",
                optionsURL: url.user,
                canShow: ({ item, values }) => loggedUser?.is_staff,
                getLabel: (v) => `${v.first_name} ${v.last_name} (${v.username})`,
                // filterOption: userFilter, noOptionsMessage: "Search to add new User", 
                // onCreateOption: newUserModal, 
                append: document.body, offset: 1, col: 5
            },
            
            {
                type: "select",
                id: "taskStatus",
                name: "status",
                title: "Status",
                options: [
                    { label: "Pending", value: "pending" },
                    { label: "Completed", value: "completed" },
                    { label: "Approved", value: "approved" },
                    // { label: "Expired", value: "expired" },
                    { label: "Rejected", value: "rejected" }
                ].filter(i => i),
                getOptions:({values, options})=>{
                    return (values?.user === loggedUser?.id && !loggedUser?.is_staff)?
                    options.filter(o=>!["approved", "rejected"].includes(o.value)):options
                },
                placeholder: "Choose Status",
                append: document.body,
                offset: 1, col: 5
            },
            {
                type: "calendar", id: "due_date__gte",
                name: "due_date__gte", title: "Due Date From",
                // value: dataJson.due_date, 
                placeholder: "Enter Due Date",
                altFormat: "F j, Y",
                dateFormat: "YYYY-MM-DD", offset: 1, col: 5,
                formatValueBeforeSubmit: (d) => {
                    return moment(d).format()
                },
            },
            {
                type: "calendar", id: "due_date__lte",
                name: "due_date__lte", title: "Due Date Until",
                // value: dataJson.due_date, 
                placeholder: "Enter Due Date",
                altFormat: "F j, Y",
                dateFormat: "YYYY-MM-DD", offset: 1, col: 5,
                formatValueBeforeSubmit: (d) => {
                    return moment(d).format()
                },
            },

        ]
    }

    return <Table 
            {...props}
            // title={"Tasks"}
            // subtitle={"Assigned tasks. Mark done when completed. Also remember to leave a comment"}
            // filter={{globalFilter: "", createdGte: monthFirstDay('month'), createdLte: monthLastDay('month')}}
            columns={columns}
            advancedSearch={advancedSearch}
            itemType = "todo.task"
            itemType_verbose="Task"
    />
    
}
