import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Spinner, Alert } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import FormAttributes from 'utilities/FormAttributes';
import { getRequest, patchRequest, postRequest, putRequest, queryParams } from 'services/CoreService';
import { CORE_MODULE } from '../../constants';
import { requests, url } from 'helpers';

export const User = ({ setDisplayModal = () => { }, setSearch = () => { }, setJson = () => { }, ...props }) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");
    const [failed, setFailed] = useState(null);

    const { dataId, displayElement = true, nonModal = true } = props.match.params;
    const [dataJson, setDataJson] = useState({
        // first_name: "",
        // last_name: "",
        // username: "",
        // email: "",
        // password: "",
        // confirm: "",
    });
    const [user_types, setUser_types] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [dataAttributes, setDataAttributes] = useState([]);
    const [hasImage, setHasImage] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const isEditing = dataId !== '0';
    const apiEndpoint = isEditing ?
        url.getURL("user", { item: { id: dataId, type: "view" } })
        : url.register


    useEffect(() => {
        if (isEditing) {
            requests.get(apiEndpoint)
                .then(data => {
                    setDataJson({
                        // id: data.id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        username: data.username,
                        email: data.email,
                    });
                }).catch(error => {

                })
        }
    }, [submitted, dataId]);

    useEffect(() => {
        let formAttributes = [];
        if (!isEditing) {
            formAttributes = [
                { type: "text", id: "first_name", name: "first_name", title: "First Name", value: dataJson.first_name, placeholder: "Enter First Name", offset: 1, col: (nonModal ? 5 : 10) },
                { type: "text", id: "last_name", name: "last_name", title: "Last Name", value: dataJson.last_name, placeholder: "Enter Last Name", offset: (nonModal ? 0 : 1), col: (nonModal ? 5 : 10) },
                { type: "text", id: "username", name: "username", title: "Username", value: dataJson.username, placeholder: "Enter Username", offset: 1, col: (nonModal ? 5 : 10) },
                { type: "text", id: "email", name: "email", title: "Email", value: dataJson.email, placeholder: "Enter Email", offset: (nonModal ? 0 : 1), col: (nonModal ? 5 : 10) },
                { type: "password", id: "password", name: "password", title: "Password", value: dataJson.password, placeholder: "Enter Password", offset: 1, col: (nonModal ? 5 : 10) },
                // { type: "password", id: "confirm", name: "confirm", title: "Confirm Password", value: dataJson.confirm, placeholder: "Enter Confirm Password", offset: (nonModal ? 0 : 1), col: (nonModal ? 5 : 10) },
            ];
        }
        else {
            formAttributes = [
                { type: "text", id: "first_name", name: "first_name", title: "First Name", value: dataJson.first_name, placeholder: "Enter First Name", offset: 1, col: (nonModal ? 5 : 10) },
                { type: "text", id: "last_name", name: "last_name", title: "Last Name", value: dataJson.last_name, placeholder: "Enter Last Name", offset: (nonModal ? 0 : 1), col: (nonModal ? 5 : 10) },
                { type: "text", id: "username", disabled: true, name: "username", title: "Username", value: dataJson.username, placeholder: "Enter Username", offset: 1, col: (nonModal ? 5 : 10) },
                { type: "text", id: "email", name: "email", title: "Email", value: dataJson.email, placeholder: "Enter Email", offset: (nonModal ? 0 : 1), col: (nonModal ? 5 : 10) },
            ];
        }
        setDataAttributes(formAttributes);
    }, [dataJson, user_types, districts, dataId]);

    const submitData = (e) => {
        e.preventDefault();
        setFailed(null);
        setLoading(true);
        requests.post(apiEndpoint, { ...dataJson, email: undefined })
            .then(response => {
                setSearch(dataJson.first_name + " " + dataJson.last_name);
                setDisplayModal(false);
                setJson({ ...(props?.dataJson || {}), [props?.modalData]: response.id });
                setLoading(false);
                setFailed(false);
                setAlert("User added successfully!");
                (props?.modalData || history.push("/users"));
            }).catch(error => {
                setDisplayModal(false);
                setLoading(false);
                setFailed(true);
                const errorMessage = error.message + "\n" + JSON.stringify(error.data)
                setAlert(errorMessage.length > 1000 ? "Check email or username. More likely it is taken already" : errorMessage);
            });
    }

    const editData = (e) => {
        e.preventDefault();
        setFailed(null);
        setLoading(true);
        requests.patch(apiEndpoint, { ...dataJson, id: undefined })
            .then(response => {
                setLoading(false);
                setFailed(false);
                setAlert("User updated successfully!");
                history.push("/users");
                setSubmitted(!submitted);
            }).catch(error => {
                setLoading(false);
                setFailed(true);
                setAlert(error.message);
            });
    }

    return (
        <React.Fragment>
            <div className={displayElement ? "page-content" : ""}>
                {displayElement &&
                    <MetaTags>
                        <title>{+dataId === 0 ? "New" : "Edit"} | User</title>
                    </MetaTags>
                }
                <Container fluid={displayElement} className={displayElement ? "" : "p-0"}>
                    {displayElement && <Breadcrumbs title={+dataId === 0 ? "New" : "Edit"} breadcrumbItem="User" />}
                    <Row>
                        <Col xs={12} sm={12} lg="12">
                            <Card className={displayElement ? "" : "p-0"}>
                                <CardBody className={displayElement ? "" : "p-0"}>
                                    <div className={displayElement ? "p-2" : ""}>
                                        <AvForm className="form-horizontal" onValidSubmit={(e) => +dataId === 0 ? submitData(e) : editData(e)}>
                                            {failed === false && (<Alert color="success">{alert}</Alert>)}
                                            {failed === true && (<Alert color="danger">{alert}</Alert>)}
                                            {loading && (<div className="mb-3 text-center"><Spinner className="ms-2" color="primary" /></div>)}
                                            <FormAttributes attributes={dataAttributes} dataJson={dataJson} setDataJson={setDataJson} />
                                            <div className="col-4 offset-4 mt-3 d-grid">
                                                <button className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" type="submit">Save</button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}