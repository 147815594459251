import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import avatar1 from "../../assets/images/users/graduate.png"
import { getFullName } from "utilities/TayomiUtil"
import { url, utils, requests } from "helpers"

export default function WelcomeComp(props) {
  const { dashboard } = props;
  const [collectionTarget, setCollectionTarget] = useState(0);
  const [paymentsTotal, setPaymentsTotal] = useState(0);
  const [toTarget, setToTarget] = useState(0);
  const [canEditCollection, setCanEditCollection] = useState(false);
  const [member, setMember] = useState();

  const loggedUser = utils.getUser();
  const isStaff = loggedUser?.is_staff;
  const isSuperuser = loggedUser?.is_superuser;


  useEffect(() => {
    setCollectionTarget(dashboard?.target_payments_total);
    setPaymentsTotal(dashboard?.payments?.total);
    setToTarget(dashboard?.to_target)

  }, [dashboard])

  useEffect(() => {
    setToTarget(collectionTarget - paymentsTotal)
  }, [collectionTarget])

  useEffect(() => {
    const fetchData = async () => {
      const res = await requests.get(url.member + loggedUser?.member_id);
      setMember(res);
    }
    fetchData()
  }, [])

  async function updateCollectionTarget() {
    if (!canEditCollection) return setCanEditCollection(!canEditCollection)
    try {
      const res = requests.post(`${url.user}set_config/`, { COLLECTION_TARGET: collectionTarget })
      setCanEditCollection(!canEditCollection)
      // alert(`Target was updated successfully`);
    } catch (error) {
      alert("Error updating target: " + error.message)
    }
  }

  const percentageToTarget = parseFloat(paymentsTotal || 0) / parseFloat(collectionTarget || 1) * 100;

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="12">
              <div className="text-default pt-3 text-center">
                <h5 className="text-default">Hi, {loggedUser?.username}</h5>
                <p>Collections and Contributions</p>
              </div>
            </Col>
            {/* <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col> */}
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img height={40} src={member?.profile_photo || avatar1} alt="" className="rounded-circle" />
              </div>

              <h5 className="font-size-15">{getFullName()}</h5>

              {/* <p className="text-muted mb-0 text-truncate">UI/UX Designer</p> */}
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col sm="6" className="pt-2">
                    <>
                      <h5 className="font-size-12">TZS {utils.formatNumber(isStaff ? (collectionTarget || 0) : (paymentsTotal || 0))}</h5>
                      <p className="text-muted mb-0">{isStaff ? "Target" : "My Contributions"}</p>
                    </>
                  </Col>
                  <Col sm="6" className="pt-2">
                    <h5 className="font-size-12">TZS {utils.formatNumber(isStaff ? (toTarget > 0 ? toTarget : 0) : (dashboard?.pending_payments || 0))}</h5>
                    <p className="text-muted mb-0">{isStaff ? `${utils.formatNumber(percentageToTarget > 100 ? 100 : percentageToTarget)}% of Target` : "Amount Due"}</p>
                  </Col>
                </Row>
                {isStaff && isSuperuser && <div className="mt-4">
                  {canEditCollection && <input
                    name="collection_target"
                    type="number"
                    className="mx-2 my-1"
                    value={collectionTarget}
                    onChange={(e) => setCollectionTarget([e.target.value])}
                  />}
                  <div
                    onClick={updateCollectionTarget}
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    {canEditCollection ? "Submit" : "Update Target"}
                  </div>
                </div>}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <h5 className="pt-2 text-center">Membership Summary</h5>
        </div>
        <CardBody className="pt-0">
          <Row>
            <div className="bg-soft pt-1">
              <p><span className="text-muted mb-0">Membership Type</span>: {member?.member_type?.name}</p>
              <p><span className="text-muted mb-0">{["CLPD", "TAYOMI"].includes(member?.member_type?.name) ? "Graduated At" : "Expected Graduation Date"}</span>: {!!member?.expected_graduation ? utils.formatDate(member?.expected_graduation, "DD-MM-YYYY") : "Unkown"}</p>
              {!!member?.branch?.name && <p><span className="text-muted mb-0">Branch Name</span>: {member?.branch?.name} </p>}
              {!!member?.joined_at && <p><span className="text-muted mb-0">Joined on</span>: {utils.formatDate(member?.joined_at, "DD-MM-YYYY")}</p>}
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

