import React from 'react';
import AddItem from "components/AddItem";
import {url, utils} from "helpers";

export default function Certificate(props){
    const loggedUser = utils.getUser()
    const isStaff = loggedUser?.is_staff;


    const config ={
        itemType:"certificate",
        // permissionsTitle:"Share Request",
        columns:[
            {
                type: "select-filter",
                id: "member", 
                name: "member", 
                title: "Member", 
                options: [], 
                placeholder: "Choose Member", 
                valueField:"member.id",
                optionsURL:url.member,
                getLabel:(item)=>`${item.user?.first_name} ${item.user?.last_name} (${item.user?.username})`,
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Type Member", 
                // onCreateOption: newRegionModal, 
                append: document.body, 
                offset: 1, 
                col:5
            },
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Name on Certificate", 
                placeholder: "Enter Name", 
                offset: 1, 
                col:5
            },
            {   
                type: "text", 
                id: "comment", 
                name: "comment", 
                title: "Comment", 
                placeholder: "Enter Comment", 
                offset: 1, 
                col:5
            },
            isStaff &&  {
                type: "select", 
                id: "status", 
                name: "status", 
                title: "Request Status",  
                options:  [
                        {label: "Pending", value: "pending"},
                        {label: "Rejected", value: "rejected"},
                        {label: "Processed", value: "processed"},
                ], 
                placeholder: "Choose Status",
                formatBeforeSubmit:(v)=>v.value,
                append: document.body, 
                offset: 1, 
                col:5,
                required: true,
            },
        ].filter(d=>d)
    }
    return <AddItem {...props} config={config} />
}