import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Alert, Row, Col, Button, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap"
import { Link } from "react-router-dom"
//import Charts
import StackedColumnChart from "./StackedColumnChart"
// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTransactions from "./LatestTransactions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import { requests, url, utils } from 'helpers';

const Dashboard = props => {
  const [dashboard, setDashboard] = useState([]);
  const [graphMenu, setGraphMenu] = useState(false);
  const [graph, setGraph] = useState("bar");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loggedUser, setLoggedUser] = useState(utils.getUser());
  const [member, setMember] = useState();
  const [isMember, setIsMember] = useState(false);
  const [isStaff, setIsStaff] = useState(loggedUser?.is_staff);
  const [certRequestMessage, setCertRequestMessage] = useState("Request Fees/Certificate Update")


  const reports = [
    { title: "Contributions", value: dashboard?.payments?.count, iconClass: "bx-copy-alt", description: dashboard?.payments?.count },
    { title: "Revenues", value: dashboard?.payments?.total, iconClass: "bx-archive-in", description: `TZS ${utils.formatNumber(dashboard?.payments?.total || 0)}` },
    {
      title: "Average Contribution",
      iconClass: "bx-purchase-tag-alt",
      value: dashboard?.payments?.average,
      description: `TZS ${utils.formatNumber(dashboard?.payments?.average || 0)}`,
    },
  ]

  async function onRequestCert() {
    try {
      setCertRequestMessage("Please wait when request is processed...")
      const res = await requests.get(url.payments.fee + "sync")
      setCertRequestMessage(res.message)
    } catch (error) {
      setErrorMessage(JSON.stringify(error.data || error.message))
    }
  }

  useEffect(() => {
    const _fetchData = async () => {
      const user = utils.getUser()
      setLoggedUser(user);
      if (!user?.member_id) {
        return utils.navigate(url.routes.profile)
      }
      setLoading(true);
      try {
        const _res = await requests.get(`${url.member}?user__id=${user?.id}`);
        const res = _res.results && _res.results[0];
        setMember(res);
        setIsMember(res?.status === 'active');
        utils.setUser({
          ...loggedUser,
          member: res,
        })
      } catch (error) {
        setErrorMessage(JSON.stringify(error.data || error.message))
      }
      setLoading(false);
    }
    requests.get(url.authuser)
      .then(data => {
        setLoggedUser({ ...loggedUser, ...data });
        utils.setUser({ ...loggedUser, ...data });
      })
      .catch(error => {
        setErrorMessage("Error getting user info" + ". " + error.message + " " + JSON.stringify(error.data))
      })
      .finally(_fetchData)

  }, []);

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const link = `${url.user}dashboard`
        const res = await requests.get(link)
        setDashboard(res);
      } catch (error) {
        setDashboard({});
        setErrorMessage(JSON.stringify(error.data || error.message))
      }
    }
    fetchDashboard();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Tayomi</title>
        </MetaTags>
        {isMember ? <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("Dashboard")} />
          <Row>
            <Col xl="4">
              <WelcomeComp dashboard={dashboard} />
              {isStaff && <MonthlyEarning dashboard={dashboard} />}
            </Col>
            <Col xl="8">
              {!!errorMessage && <Row>
                <Alert color='danger'>{errorMessage}</Alert>
              </Row>}
              {isStaff && <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium"> {report.title}</p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div title={report.value} className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle">
                              <i className={"bx " + report.iconClass + " font-size-16"}></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>}

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Registered Members</h4><br />
                    <div className="ms-auto">
                      <ul className="nav nav-pills align-text-top">
                        <li className="nav-item dropdown">
                          <Dropdown isOpen={graphMenu} toggle={() => setGraphMenu(!graphMenu)} className="d-inline-block">
                            <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                              <span className="d-none d-xl-inline-block ms-2 me-1 font-size-14"><strong>Chart Format</strong></span><i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem>
                                <Link className={graph === "line" ? "nav-link active btn btn-sm" : "nav-link btn btn-sm"} to="#" onClick={() => setGraph("line")}>
                                  <i className="bx bx-line-chart font-size-16 align-middle me-1" /><span>Line Chart</span>
                                </Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className={graph === "bar" ? "nav-link active btn btn-sm" : "nav-link btn btn-sm"} to="#" onClick={() => setGraph("bar")}>
                                  <i className="bx bx-bar-chart font-size-16 align-middle me-1" /><span>Bar Chart</span>
                                </Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link className={graph === "heatmap" ? "nav-link active btn btn-sm" : "nav-link btn btn-sm"} to="#" onClick={() => setGraph("heatmap")}>
                                  <i className="bx bx-area font-size-16 align-middle me-1" /><span>Heat Map</span>
                                </Link>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart graph={graph} setGraph={setGraph} height={isStaff ? "290" : "275"} />
                  <Row className='mt-4'>
                    <Col sm="6">
                      <p className="text-muted fw-medium">
                        Total members: {utils.formatNumber(dashboard?.total_members || 0)}
                      </p>
                    </Col>
                    <Col sm="6">
                      {isStaff && <button className="btn btn-primary waves-effect waves-light btn-sm" onClick={onRequestCert}>
                        {certRequestMessage}
                      </button>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {/* <Col xl="4">
              <SocialSource />
            </Col> */}
            {isStaff && <Col xl="4">
              <TopCities dashboard={dashboard} />
            </Col>}
            <Col xl={isStaff ? "8" : "12"}>
              <ActivityComp />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <LatestTransactions />
            </Col>
          </Row>
        </Container> :
          <Container fluid>
            <div className='text-center justify-content pt-4'>
              {!loading && <Link to={member?.id ? "#" : url.routes.profile}>
                <h2 style={{ color: "red" }}>
                  {member?.id ? 
                  (member.status === 'pending' ? 
                    `Your account is pending for verification. Wait for Approval.` 
                    : `You have no permission to access this account. Please contact the system administrator`
                  ) 
                  : `Please complete your registration to proceed`}
                </h2>
              </Link>}
              {!!loading && <div className='text-center justify-content pt-4'>
                Loading....
              </div>}
            </div>
          </Container>
        }
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
