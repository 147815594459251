import React, { useEffect, useState } from "react"
import Table from "components/Table";
import { utils, url } from "helpers";
import { CONTENT_IDS } from "../../constants"

export default function Members(props) {
  const nonModal = true
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff

  useEffect(() => {
    if (!loggedUser?.member) {//not logged in user
      utils.navigate(url.routes.dashboard)
    }
  }, [])

  const isCLPDField = ({ item, values }) => {
    return CONTENT_IDS.member_type[`${values?.member_type__id}`]?.name === "CLPD"
  }

  const advancedSearch = {
    itemType: "member",
    canEdit: ({ item, values }) => !(item?.id && !isStaff),
    columns: [
      {
        type: "text",
        id: "membership_no",
        name: "membership_no",
        title: "Membership Number",
        placeholder: "Enter Membership Number",
        disabled: true,
        canShow: ({ item, values }) => !!item?.membership_no,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
      },

      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "member_type__id",
        name: "member_type__id",
        title: "Member/Leader Type",
        optionsURL: url.membertype,
        valueField: "member_type.id",
        _setValue: item => {
          return { value: item?.member_type?.id, label: item?.member_type?.name }
        },
        options: [],
        placeholder: "Choose Member Type",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        getOptionsParams: state => ({ search: state.member_type || "" })
      },
      {
        type: "text",
        id: "phone",
        name: "phone",
        title: "Phone Number",
        placeholder: "Enter Phone Number",
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        required: false,
      },

      {
        type: "select",
        id: "memberStatus",
        name: "status",
        title: "Member Status",
        options: [
          { label: "Pending", value: "pending" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ],
        placeholder: "Choose Status",
        canShow: ({ item, values }) => item?.id,
        // setValue: item => {
        //   return { value: item?.status, label: item?.status }
        // },
        formatBeforeSubmit: v => v.value,
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        required: false,
      },
      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "region__id",
        name: "region__id",
        valueField: "region.id",
        title: "Residence Region",
        options: [],
        optionsURL: url.region,
        placeholder: "Choose Region",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: isCLPDField,
      },
      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        id: "branch__id",
        name: "branch__id",
        valueField: "branch.id",
        title: "Branch",
        optionsURL: url.branch,
        options: [],
        placeholder: "Choose Branch",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: (params) => !isCLPDField(params),
      },
      {
        type: "date",
        id: "joined_at",
        name: "joined_at",
        title: "Joined At",
        required: false,
        placeholder: "Enter Joined Date",
        altFormat: "F j, Y",
        dateFormat: "YYYY-MM-DD",
        formatBeforeSubmit: v => moment(v).format("YYYY-MM-DD"),
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: "date",
        id: "born_again_at",
        name: "born_again_at",
        title: "Born Again Date",
        placeholder: "EnterBorn Again Date",
        altFormat: "F j, Y",
        dateFormat: "YYYY-MM-DD",
        validate: (value) => {
          let error = undefined;
          if (value) {
            if (moment(value) > moment(new Date())) {
              error = "This date is not valid. Born again should be something in the past ";
            }
          }
          return [value, error]
        },
        required: false,

        formatBeforeSubmit: v => moment(v).format(),
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: "date",
        id: "expected_graduation",
        name: "expected_graduation",
        title: "Graduated At",
        placeholder: "Enter Graduation Date",
        altFormat: "F j, Y",
        required: false,
        dateFormat: "YYYY-MM-DD",
        validate: (value) => {
          let error;
          if (value) {
            if (moment(value) > moment(new Date())) {
              error = "This graduation date is not valid. Entere a value in the past ";
            }
          }
          return [value, error]
        },
        formatBeforeSubmit: v => moment(v).format(),
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: isCLPDField,
      },
      {
        type: "date",
        required: false,
        id: "expected_graduation",
        name: "expected_graduation",
        validate: (value) => {
          let error;
          if (value) {
            if (moment(value) < moment(new Date())) {
              error = "This Expected graduation date is not valid. Entere a value in the future ";
            }
          }
          return [value, error]
        },
        title: "Expected Graduation",
        placeholder: "Enter Expected Graduation",
        altFormat: "F j, Y",
        dateFormat: "YYYY-MM-DD",
        formatBeforeSubmit: v => moment(v).format(),
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: (params) => !isCLPDField(params),
      },
      {
        type: "text",
        required: false,
        id: "program",
        name: "program",
        title: "Program",
        placeholder: "E.g Bsc. in Agriculture",
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: (p) => !isCLPDField(p),
      },

      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "grade__id",
        name: "grade__id",
        valueField: "grade.id",
        title: "Level of Study",
        options: [],
        optionsURL: url.grade,
        placeholder: "Choose Grade",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: (p) => !isCLPDField(p),
      },
      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "current_church__id",
        name: "current_church__id",
        valueField: "current_church.id",
        title: "Current Church",
        options: [],
        optionsURL: url.church,
        placeholder: "Choose Current Church",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "home_church__id",
        name: "home_church__id",
        valueField: "home_church.id",
        title: "Home Church",
        options: [],
        optionsURL: url.church,
        placeholder: "Choose Church",
        noOptionsMessage: "Search to add new Church",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: (params) => !isCLPDField(params),
      },
      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "leaderships__id",
        name: "leaderships__id",
        title: "Leadership Title",
        optionsURL: url.leadership,
        options: [],
        placeholder: "Choose Leadership",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        getOptionsParams: state => ({ member_type__id: state.member_type || "" })
      },
      {
        type: nonModal ? "select-filter" : "select-modal-filter",
        required: false,
        id: "department__id",
        name: "department__id",
        valueField: "department.id",
        title: "Department",
        options: [],
        optionsURL: url.department,
        canShow: (p) => !isCLPDField(p),
        placeholder: "Choose Department",
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
      },
      {
        //only appear to staff
        type: "select-filter",
        id: "user__id",
        name: "user__id",
        title: "User",
        options: [],
        value: loggedUser?.id,
        canShow: item => isStaff,
        placeholder: "Choose User",
        valueField: "user.id",
        optionsURL: url.user,
        getLabel: item =>
          `${item?.first_name} ${item?.last_name} (${item?.username})`,
        append: document.body,
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        required: false,
      },
      {
        type: "text",
        id: "career",
        name: "career",
        title: "Career/Profession",
        placeholder: "E.g Accountant",
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
        canShow: isCLPDField,
      },
      {
        type: "text",
        id: "talent",
        name: "talent",
        title: "Talent",
        placeholder: "Eg. Singing",
        offset: nonModal ? 0 : 1,
        col: nonModal ? 5 : 10,
      },
    ].filter(attr => attr) //remove all undefined fields,
  }

  const columns = [
    {
      label: "Member ID",
      field: "membership_no",
      formatValue: (v) => v || "UNASSIGNED",
      sort: "asc",
      width: 250,
    },
    {
      label: "Username",
      field: "user.username",
      // formatValue:(user)=>`${user?.username}`,
      sort: "asc",
      width: 250,
    },
    {
      label: "Name",
      field: "user",
      formatValue: (user) => `${user?.first_name} ${user?.last_name}`,
      sort: "asc",
      width: 250,
    },

    {
      label: "Branch",
      field: "branch.name",
      sort: "asc",
      width: 250,
    },
    {
      label: "Member Type",
      field: "member_type.name",
      sort: "asc",
      width: 250,
    },
    {
      label: "Region",
      field: "region.name",
      formatValue:(v, item)=>v||item?.branch?.district?.region?.name,
      sort: "asc",
      width: 250,
    },
    {
      label: "Home Church",
      field: "home_church.name",
      sort: "asc",
      width: 250,
    },
    {
      label: "Current Church",
      field: "current_church.name",
      sort: "asc",
      width: 250,
    },
    {
      label: "Leadership Title",
      field: "leaderships",
      formatValue:(v, item)=>v?.map(t=>t.name).join(", "),
      sort: "asc",
      width: 250,
    },
    {
      label: "Registered At",
      field: "created",
      formatValue: (v) => utils.formatDate(v, "lll"),
      sort: "asc",
      width: 250,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 250,
    },
    {
      label: "Actions",
      field: "action",
      sort: "asc",
      width: 100,
      canEdit: true,
      canDelete: true,
    },
  ];

  return <Table
    {...props}
    columns={columns}
    summaryColumns={[{ title: "Total", field: "count" }]}
    itemType="member"
    itemType_verbose="Members"
    advancedSearch={advancedSearch}
  />

}
