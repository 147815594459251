import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils} from "helpers"
export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function MemberTypes(props){
    return <Table 
            columns={columns}
            itemType = "membertype"
            itemType_verbose="Membership Type"
    />
    
}
