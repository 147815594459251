import React from "react"
import AddItem from "components/AddItem"
import { url, utils } from "helpers"

export default function Department(props) {
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff;
  const displayElement = true;
  const nonModal = true;

  const columns = [
    {
      type: "text",
      id: "name",
      name: "name",
      title: "Department Name",
      placeholder: "Enter name",
      offset: displayElement ? 0 : 1,
      col: displayElement ? 5 : 10,
    },


    { //only appear to staff
        type: "select-filter",
        id: "branch", 
        name: "branch", 
        title: "Branch", 
        options: [], 
        canShow:({item, values})=>isStaff,
        placeholder: "Choose Branch", 
        valueField:"branch.id",
        optionsURL:url.branch,
        getLabel:(item)=>`${item?.name}`,
        // filterOption: regionFilter, 
        noOptionsMessage: "Search Branch", 
        // onCreateOption: newRegionModal, 
        append: document.body, 
        offset: displayElement ? 0 : 1,
        col: displayElement ? 5 : 10,
    },
    {
      type: "text",
      id: "code",
      name: "code",
      title: "Code",
      placeholder: "Enter Code",
      offset: displayElement ? 0 : 1,
      col: displayElement ? 5 : 10,
    },
  ]

  const config = {
    itemType: "department",
    itemType_verbose:"Department",
    permissionsTitle:"Manage Access",
    // canEdit: ({item, values}) => !(item?.id && !isStaff),
    columns,
  }

  return <AddItem {...props} config={config} />
}
