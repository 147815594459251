import React from 'react';
import AddItem from "components/AddItem";

export default function TaskCategory(props){
    const config ={
        itemType:"todo.taskcategory",
        itemType_verbose:"Task Category",
        columns:[
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Task Name", 
                placeholder: "Give it a name", 
                offset: 1, 
                col:5
            },
            {   
                type: "text", 
                id: "description", 
                name: "description", 
                title: "Description", 
                placeholder: "Describe it", 
                offset: 1, 
                col:5
            },
        ]
    }

    return <AddItem {...props} config={config} />
}