import React, { useState, useEffect } from "react";
import {
  Button, Row, Table,
} from "reactstrap"
import {Link} from "react-router-dom";
import { url, utils, requests } from "helpers"
import { Dropzone, FileItem,FileItemImage, FullScreenPreview } from "@dropzone-ui/react";
import FilePreviewer from 'react-file-previewer';
import _ from "lodash";

export default function Attachment(props) {
  const {itemURL, attachmentQuery={advanced_search:"transactions__id:19"}, onSubmit} = props;

  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [uploadedFiles, setUploadeFiles] = useState([]);

  async function fetchAttachments(){
    const res = await requests.get(
      utils.stringify(attachmentQuery, {baseURL:url.attachment})
    )
    res.results && setUploadeFiles(res.results?.map(item=>({
      id:item.id, serverResponse:item
    })));

  }

  useEffect(()=>{
    fetchAttachments()
  }, [])
  

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };

  const onDelete = (id) => {
    /*
    remove the file from both uploaded and local files
    */
    setFiles(files.filter((x) => x.id !== id));
    // send delete request to server
    setUploadeFiles(uploadedFiles.filter((x) => x.id !== id))
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  async function updateAttachments(){
    const allIds = uploadedFiles.map(up=>up.serverResponse.id).filter(v=>v);
    const remove = [];
    const attachments = { "add": allIds, remove }
    const res = await requests.patch(itemURL, {
      attachments
    });
    onSubmit && onSubmit()
    alert("All attchments were updated")
  }
  /*
   [{"id":8,
   "serverResponse":{"id":34,"name":"","file":"http://localhost:8001/media/upload/files/Web_capture_18-1-2022_224554_phoenix.whereismytransport.com_3QQrkaH.jpeg",
   "image":null,"status":true,"message":"File was uploaded successfuly"}}]
  */

   function download(file){
    const link = document.createElement('a');
    link.href = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
   }

  return (
    <div className="p-4">
      {/*uploadedFiles.map((file, index)=>(
        <img key={index} 
        onClick={()=>handleSee(file.serverResponse.file)} 
        src={file.serverResponse.file} 
        className="img-responsive p-2" 
        height={100}/>
      ))*/}
      {/*uploadedFiles.map((file, index)=>(
        false?<FilePreviewer 
          hideControls
          onClick={()=>download(file.serverResponse.file)}
          file={{
            url: file.serverResponse.file}}
            />:<a href={file.serverResponse.file} className="mb-1">
                {index+1}.  {file.serverResponse.file.split("/").slice(-1)[0]} <br/>
            </a>
        ))*/}

        <Table striped>
                <tbody>
                {uploadedFiles.map((file, index)=>(
                        <tr>
                            <td>{index+1}</td>
                            <td>{file.serverResponse.file.split("/").slice(-1)[0]}</td>
                            {/* <td className="alert-info">
                                <Link onClick={() =>handleSee(file.serverResponse.file)}>
                                    Preview
                                </Link>
                            </td> */}
                            <td className="alert-success">
                                <a href={file.serverResponse.file} target="_blank">
                                  View
                                </a>
                            </td>

                        </tr>
                ))}
                    
                </tbody>
            </Table>
            
        <div className = "pt-2"/>
      <Dropzone
        onChange={updateFiles}
        value={files}
        method="POST"
        url={url.BASE_URL + url.attachment}
        uploadOnDrop={true}
        config={{ headers: { "content-type": "multipart/form-data;", }, }}
        onUploadFinish={(res) => {
          // console.log("results ", JSON.stringify(res));
          setUploadeFiles([...uploadedFiles, ...res])
        }}
      >
        {files.map((file, index) => (
          <FileItem 
          {...file} 
          preview info hd
          key={index}
          onDelete={onDelete}
          onSee={handleSee}
            // uploadStatus="uploading"
          />
        ))}
      </Dropzone>
          <Row>
            <Button
              type="button"
              onClick={updateAttachments}
              color="secondary"
              className="btn btn-sm btn-rounded waves-effect waves-light mt-4 me-1 py-2 "
            >
              <span> Submit </span>
            </Button>
      </Row>
      <FullScreenPreview
        imgSource={imageSrc}
        openImage={imageSrc}
        onClose={(e) => handleSee(undefined)}
      />

    </div>
  );
}
