import React from 'react';
import AddItem from "components/AddItem";
import { url, utils } from "helpers";
import moment from "moment";

export default function Transaction(props) {
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff;
  const nonModal = true;
  const displayElement = true;

  const config = {
    itemType: "payments.transaction",
    itemType_verbose: "Transaction",
    permissionsTitle: "Share Transaction",
    attachmentQuery: { advanced_search: "transactions__id:{id}" },
    columns: [

      {
        type: "datetime",
        id: "due_date",
        name: "due_date",
        title: "Due Date",
        // value: dataJson.due_date,
        placeholder: "Enter Due Date",
        formatBeforeSubmit: (v) => moment(v).format(),
        altFormat: "F j, Y",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        offset: 1,
        col: nonModal ? 5 : 10,
        require: true,
      },
      {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "from_account",
        name: "from_account",
        title: "From Account",
        // value: dataJson.from_account,
        options: [],
        require: true,
        optionsURL: url.payments.account,
        valueField: "from_account.id",
        placeholder: "Choose Account",
        noOptionsMessage: "Search to add new Account",
        // getOptionsParams: state => ({ search: state.from_account }),
        getOptionsParams: (state, obj) => {
          return ({ search: obj?.from_account?.id })
        },
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "to_account",
        name: "to_account",
        title: "To Account",
        // value: dataJson.to_account,
        options: [],
        optionsURL: url.payments.account,
        valueField: "to_account.id",
        placeholder: "Choose Account",
        noOptionsMessage: "Search to add new Account",
        getOptionsParams: (state, obj) => ({ search: obj?.to_account?.id }),
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
        require: true,
      },
      {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "transaction_category",
        name: "transaction_category",
        title: "Transaction Category",
        optionsURL: url.payments.transactioncategory,
        valueField: "transaction_category.id",
        // value: dataJson.transaction_category,
        options: [],
        placeholder: "Choose Category",
        noOptionsMessage: "Search to add new Transaction Category",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },

      {
        type: "text", readOnly: true, disabled: true,
        id: "amount_paid", name: "amount_paid",
        title: "Amount Paid",
        offset: 1, col: nonModal ? 5 : 10,
      },
      {
        type: "text", readOnly: true, disabled: true,
        id: "amount_due", name: "amount_due",
        title: "Amount Due (TZS)",
        offset: 1, col: nonModal ? 5 : 10,
      },
      {
        type: "decimal",
        id: "amount",
        name: "amount",
        title: "Total Amount",
        // value: dataJson.amount,
        placeholder: "Enter Amount",
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      false && {
        type: "boolean",
        id: "is_paid",
        name: "is_paid",
        title: "Is Paid",
        // value: dataJson.is_paid,
        placeholder: "Tick if Paid",
        offset: 1,
        col: nonModal ? 5 : 10,
      },
    ].filter(v => v)
  }
  return <AddItem {...props} config={config} />
}


