import React, { Component, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"
import { dateFormatShort, getShortString } from "utilities/TayomiUtil";
import {url, requests} from "helpers";

const ActivityComp = () => {
    const [activities, setActivities] = useState([]);
    const fetchData = () => {
        const paramsJson = {index: 1, page: 1, size: 5 };
        requests.get(url.todo.task, paramsJson)
        .then(response => {
            setActivities(response.results);
        }).catch(error=>{

        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-5">Activities</CardTitle>
            <ul className="verti-timeline list-unstyled">
                { activities.map((item, i) => (
                    <li className="event-list" key={i}>
                        <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18"/>
                        </div>
                        <Media>
                            <div className="me-3">
                                <h5 className="font-size-14">
                                    {dateFormatShort(item.due_date)}{" "} <i className="bx bx-right-arrow-alt font-size-14 text-primary align-middle ms-2"/>
                                </h5>
                            </div>
                            <Media body>
                                <div id="activitytext">
                                 {getShortString(item.name, 150)} { item.name.length > 150 ? <Link to={url.routes.get("task", item)}>Read More</Link> : ""}
                                </div>
                            </Media>
                        </Media>
                    </li>
                ))}
            </ul>
            <div className="text-center mt-3">
                {activities?.length?<Link to="/todo/tasks" className="btn btn-primary waves-effect waves-light btn-sm">
                    View More <i className="mdi mdi-arrow-right ms-1"/>
                </Link>:
                <div className="p-4">
                    Currently you do not have any activity. Tasks and todo list will appear here.
                </div>}
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
}

export default ActivityComp
