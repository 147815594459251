import React from 'react';
import AddItem from "components/AddItem";
import {url} from "helpers";

export default function Leadership(props){
    const config ={
        itemType:"grade",
        itemType_verbose: "Level of Study",
        columns:[
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Level of Study Name", 
                placeholder: "Eg. Form One", 
                offset: 1, 
                col:5
            },
        ]
    }

    return <AddItem {...props} config={config} />
}