import React from "react"
import AddItem from "components/AddItem"
import { url } from "helpers"

export default function District(props) {
  const config = {
    itemType: "district",
    columns: [
        {
            type: "select-filter",
            id: "region",
            name: "region",
            title: "Region",
            valueField: "region.id",
            optionsURL: url.region,
            formatBeforeSubmit: v => v.value,
            options: [],
            placeholder: "Choose Region",
            noOptionsMessage: "Search to add new region",
            append: document.body,
            offset: 0,
            col: 5,
          },
      {
        type: "text",
        id: "name",
        name: "name",
        title: "District Name",
        placeholder: "Enter Name",
        offset: 1,
        col: 5,
      },

    ],
  }

  return <AddItem {...props} config={config} />
}
