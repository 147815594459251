import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {url, utils} from "helpers";
import { useParams } from "react-router-dom";

export const columns = [

    {
        label: "Amount",
        field: "amount",
        sort: "asc",
        formatValue:utils.formatNumber,
        width: 250,
    },
    {
        label: "Reference Number",
        field: "reference",
        sort: "asc",
        width: 250,
    },
    {
        label: "Note",
        field: "note",
        sort: "asc",
        width: 250,
    },
    {
        label: "Date Created",
        field: "created",
        formatValue:(v, item)=>utils.formatDate(v),
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Payments(props){
    const filter = utils.getSearchParams(window.location.href)
    const addItemLink = `${url.routes.get("payment", {id:0})}?transaction=${filter.transaction__id}`
    return <Table 
            columns={columns}
            filter={filter}
            itemType = "payments.payment"
            itemType_verbose="Payment"
            addItemLink={`${addItemLink}&nextPage=${url.routes.payments}?transaction__id=${filter.transaction__id}`}
    /> 
}