import React from 'react';
import AddItem from "components/AddItem";
import {url} from "helpers";

export default function MemberType(props){
    const config ={
        itemType:"membertype",
        title:"Member/Leader Type",
        permissionsTitle:"Manage Access",
        hideGroups:true,
        subtitle:"",
        columns:[
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Name", 
                placeholder: "Enter Name", 
                offset: 1, 
                col:5
            },
            {   
                type: "text", 
                id: "Description", 
                name: "description", 
                title: "Description", 
                placeholder: "Describe it", 
                offset: 1, 
                col:5
            }
        ]
    }

    return <AddItem {...props} config={config} />
}