import { ACCESS_TOKEN } from '../constants';

export const postForms = (options) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
    })

    if(token) {
        headers.append('Authorization', 'Token ' + token)
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postFiles = (options) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = new Headers({
    })

    if(token) {
        headers['Authorization'] = 'Token ' + token
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postRequest = (options) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = {
        'Content-Type': 'application/json',
    }

    if(token) {
        headers['Authorization'] = 'Token ' + token
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, {method: 'POST'}, options);

    return fetch(options.url, options)
    .then(response => response.json()
        .then(json => {
            // if(!response.ok || response.status >= 400) {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const putRequest = (options) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = {
        'Content-Type': 'application/json',
    }

    if(token) {
        headers['Authorization'] = 'Token ' + token
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, {method: 'PUT'}, options);

    return fetch(options.url, options)
    .then(response => response.json()
        .then(json => {
            // if(!response.ok || response.status >= 400) {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const patchRequest = (options) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = {
        'Content-Type': 'application/json',
    }

    if(token) {
        headers['Authorization'] = 'Token ' + token
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, {method: 'PATCH'}, options);

    return fetch(options.url, options)
    .then(response => response.json()
        .then(json => {
            // if(!response.ok || response.status >= 400) {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const deleteRequest = (options) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = {
        'Content-Type': 'application/json',
    }

    if(token) {
        headers['Authorization'] = 'Token ' + token
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, {method: 'DELETE'}, options);

    return fetch(options.url, options)
    .then(response => response.json()
        .then(json => {
            // if(!response.ok || response.status >= 400) {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const getRequest = (options = {}) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const headers = {}
    if(token) {
        headers['Authorization'] = 'Token ' + token
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, {method: 'GET'}, options);
    return fetch(options.url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const queryParams = params => {
    if (!params)
        return ""
    let out = Object.keys(params).map((value, key) => {
        if (key === 0)
            return `?${value}=${params[value]}`
        else
            return `&${value}=${params[value]}`
    })
    return out.join("")
};