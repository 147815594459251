import React from 'react';
import AddItem from "components/AddItem";
import {url} from "helpers";

export default function Denomination(props){
    const config ={
        itemType:"denomination",
        columns:[
            {   
                type: "text", 
                id: "name", 
                name: "name", 
                title: "Denomination Name", 
                placeholder: "Enter Name", 
                offset: 1, 
                col:5
            },
            ]
    }

    return <AddItem {...props} config={config} />
}