import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils} from "helpers";
export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Denomination",
        field: "denomination",
        formatValue:(item)=>item?.name,
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
        canEdit:true,
        canDelete:true,
    },
];


export default function Churches(props){
    return <Table 
            {...props}
            columns={columns}
            itemType = "church"
            itemType_verbose="Church"
    />
    
}
