import React, { useState,useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, } from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import UserInfo from "./UserInfo";
import MemberInfo from "../Member/MemberInfo";
import { utils, requests, url } from "helpers";

const Profile = () => {

    const loggedUser = utils.getUser();
    const isMember = loggedUser?.member_id;
    const [active, setActive] = useState(isMember?"UserInfo":"MemberInfo");

    useEffect(()=>{
        // fetch user profile to get an updated data and redirect profile to dashboard
        return ()=>{
            requests.get(url.authuser)
            .then(res => {
                const data = { ...loggedUser, ...res };
                utils.setUser(data);
                if(data.member_id){
                    utils.navigate(url.routes.dashboard);
                    document.location.reload(false);
                }
            }).catch(error => {
              alert("Error getting user info" + ". " + error.message+" "+JSON.stringify(error.data))
            })
        }
    }, [])
    
    const toggleTab = (tab) => {
        if (active !== tab) {
            setActive(tab);
        }
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Profile | TAYOMI </title></MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Settings" breadcrumbItem="Profile" />
                    <div className="checkout-tabs">
                        <Row>
                            <Col xs={12} sm={12} lg="2">
                                <Nav className="flex-column" pills>
                                    <NavItem>
                                        <NavLink className={classnames({ active: active === "UserInfo", })} onClick={() => { toggleTab("UserInfo") }}>
                                            <i className="bx bx-user d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">Personal Details</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: active === "MemberInfo", })} onClick={() => { toggleTab("MemberInfo") }}>
                                            <i className="bx bx-user-circle d-block check-nav-icon mt-4 mb-2" />
                                            <p className="font-weight-bold mb-4">Member Details</p>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col xs={12} sm={12} lg="10">
                                <Card>
                                    <CardBody>
                                        <TabContent activeTab={active}>
                                            <TabPane tabId="UserInfo">
                                                <UserInfo />
                                            </TabPane>
                                            <TabPane tabId="MemberInfo">
                                                <MemberInfo />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Profile;