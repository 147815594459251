import React from 'react';
import AddItem from "components/AddItem";
import {url, utils} from "helpers";

export default function BranchType(props){
    const loggedUser = utils.getUser()
    const config ={
        itemType:"branchtype",
        itemType_verbose:"Branch Type",
        // permissionsTitle:"Share Request",
        columns:[
          {   
            type: "text", 
            id: "name", 
            name: "name", 
            title: "Name", 
            placeholder: "Enter Name", 
            offset: 1, 
            col:5
        }

        ].filter(d=>d)
    }
    return <AddItem {...props} config={config} />
}