import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils} from "helpers";
export const columns = [
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 250,
  },
  {
    label: "Permissions",
    field: "permissions",
    sort: "asc",
    formatValue: permissions => {
      return utils.truncate({
        text: permissions?.map(p => p.name).join("; "),
        size: 121,
      })
    },
    width: 250,
  },

  {
    label: "Actions",
    field: "action",
    sort: "asc",
    width: 100,
    canEdit: true,
    canDelete: true,
  },
]

export default function Groups(props) {
  return (
    <Table
      {...props}
      columns={columns}
      itemType="group"
      filter={Object}
      itemType_verbose="User Group"
    />
  )
}
