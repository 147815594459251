import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { toggleLeftmenu } from "store/actions"
import { utils, requests, url } from "helpers"


function filterNavItem(item) {
  const loggedUser = utils.getUser();
  const isStaff = loggedUser?.is_staff;
  if (item.groups) {
    if (item.groups.includes("staff")) {
      return isStaff
    } else {
      return true
    }
  } else {
    return true
  }
}

function NavItem(props) {
  const { navItem } = props;
  const [show, setShow] = useState(false);
  const hasSub = navItem.items?.length > 0;


  const toggleShow = () => {
    setShow(!show)
  }


  return <li className="nav-item dropdown">
    <Link
      className="nav-link dropdown-toggle arrow-none"
      onClick={e => {
        hasSub && e.preventDefault()
        toggleShow();
        !hasSub && props.toggleLeftmenu(false)
      }}
      to={navItem.to}>
      <i className={navItem.icon}></i>
      {navItem.title} {props.menuOpen}{hasSub && <div className="arrow-down"></div>}
    </Link>
    {hasSub && (
      <div className={classname("dropdown-menu", { show })}>
        {navItem.items?.filter(filterNavItem).map((listItem, j) => (
          <Link key={`link-${j}`} onClick={() => props.toggleLeftmenu(false)} to={listItem.to} className="dropdown-item">
            {listItem.title}
          </Link>))
        }
      </div>)
    }
  </li>

}

const Navbar = props => {
  const loggedUser = utils.getUser();
  const [member, setMember] = useState(loggedUser?.member);
  const [isMember, setIsMember] = useState(loggedUser?.member?.status === "active");

  useEffect(()=>{
    const fetchData = async()=>{
      const res= await requests.get(url.member+loggedUser?.member_id);
      setMember(res);
      setIsMember(res.status === 'active');
    }
    fetchData()
  },[])

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul?.getElementsByTagName("a") || []
    for (var i = 0; i < items.length; ++i) {
      const link = items[i].toString()
      const currentLink = link.substring(link.indexOf("#") + 1)
      if (props.location.pathname === currentLink) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }


  const nav = [
    {
      to: "/dashboard",

      className: "nav-item",
      icon: 'bx bx-home-circle ms-2',
      title: props.t("Dashboard"),
      items: []
    },
    {
      to: "/users",
      className: "nav-item",
      icon: 'bx bx-user-check ms-2',
      title: props.t("Users"),
      groups: ["staff"],
      items: [
        { to: "/users", title: props.t("Users") },
        { to: "/groups", title: props.t("Groups") }
      ]
    },
    {
      to: "/members",
      className: "nav-item",
      icon: 'bx bx-group ms-2',
      title: props.t("Members"),
      items: [
        { to: "/members", title: props.t("Members"), groups: ["staff"], },
        { to: "/membertypes", title: props.t("Member Types"), groups: ["staff"] },
        { to: "/denominations", title: props.t("Denominations"), groups: ["staff"], },
        { to: "/leaderships", title: props.t("Leaderships"), groups: ["staff"], },
        { to: "/grades", title: props.t("Level of Study"), groups: ["staff"], },
        { to: "/certificates", title: props.t("Certificates") },
      ]
    },
    {
      to: "/branchs",
      className: "nav-item",
      icon: 'bx bxs-branch ms-2',
      title: props.t("Branches"),
      groups: ["staff"],
      items: [
        { to: "/branchs", title: props.t("Branches") },
        { to: "/branchtypes", title: props.t("Branch Types") },
        { to: "/departments", title: props.t("Departments") },
        { to: "/churchs", title: props.t("Churches"), groups: ["staff"], },
      ]
    },
    {
      to: "/regions",
      className: "nav-item",
      icon: 'bx bxs-branch ms-2',
      groups: ["staff"],
      title: props.t("Administration"),
      items: [
        { to: url.routes.zonals, title: props.t("Zonals") },
        { to: url.routes.regions, title: props.t("Regions") },
        { to: url.routes.districts, title: props.t("Districts") },
      ]
    },
    {
      to: "/todo/tasks",
      className: "nav-item",
      icon: 'bx bx-task ms-2',
      title: props.t("Tasks"),
      items: [
        { to: "/todo/tasks", title: props.t("Tasks") },
        { to: "/todo/taskcategorys", title: props.t("Task Categories"), groups: ["staff"], },
      ]
    },


    {
      to: "/payments/transactions",
      className: "nav-item",
      icon: 'bx bx-money ms-2',
      title: props.t("Payments"),

      items: [
        { to: "/payments/transactions", title: props.t("Transactions") },
        { to: "/payments/transactioncategorys", title: props.t("Transaction Categories"), groups: ["staff"], },
        { to: "/payments/accounts", title: props.t("Accounts") },
        { to: "/payments/fees", title: props.t("Fees")},
      ]
    },

  ].filter(filterNavItem)

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          {isMember && <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                {nav.map((navItem, index) => (
                  <NavItem navItem={navItem}  {...props}  key={`nav-${index}`} />
                ))}
              </ul>
            </Collapse>
          </nav>}
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { leftMenu, toggleLeftmenu } = state.Layout
  return { leftMenu, toggleLeftmenu }
}

export default withRouter(
  connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar))
)
