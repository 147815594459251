import React, { useState, useEffect } from "react"
import { Container } from "reactstrap";
import { requests, url, utils } from 'helpers';

const html = `<p className="pt-4 text-center"><strong>TAYOMI Membership Management System Terms of Use</strong></p>
<p>Effective as of February 07, 2021</p>
<p>Welcome to the TAYOMI Membership Management System Terms of Use agreement. For purposes of this agreement, &ldquo;Site&rdquo; refers to the TAYOMI Membership Management System, which can be accessed through <a href="http://mms.tayomi.or.tz">http://mms.tayomi.or.tz</a> </p>
<p>&ldquo;TAYOMI&rdquo; refers to Tanzania Youth Ministries. &ldquo;Service&rdquo; refers to the organization&rsquo;s services accessed via the Site, in which users can register, read, and use information available on our site.</p>
<p>&ldquo;Member&rdquo; refers to all students joining The Christ&rsquo;s Ambassadors Students Fellowship Tanzania (CASFETA) in schools, College and Universities in compliance with the TAYOMI constitution and CASFETA bylaws. It also refers to TAYOMI Coordinators at all levels of leadership, and graduates who were CASFETA members herein refers to as Christian Life and Professionals Development (CLPD).</p>
<p>&ldquo;TAYOMI official website&rdquo; refers to <a href="http://www.tayomi.or.tz">www.tayomi.or.tz</a></p>
<p>The terms &ldquo;we,&rdquo; &ldquo;us,&rdquo; and &ldquo;our&rdquo; refer to the Tanzania Youth Ministries. &ldquo;You&rdquo; refers to you, as a user of our Site or our Service.</p>
<p>For this agreement &ldquo;Branch&rdquo; means primary school or secondary school or college or university within which TAYOMI operates.</p>
<p>The following Terms of Use apply when you view or use the Service via our TAYOMI Membership Management System located at <a href="http://mms.tayomi.or.tz">http://mms.tayomi.or.tz</a> or by accessing the service through official TAYOMI website.&nbsp;</p>
<p>Please review the following terms carefully. By accessing or using the Service, you signify your agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in their entirety, you may not access or use the Service.&nbsp;</p>
<p><strong>PRIVACY POLICY</strong></p>
<p>The organization respects the privacy of its Service users. Please refer to the Organization&rsquo;s Privacy Policy which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Service, you signify your agreement to the Privacy Policy as well as these Terms of Use.</p>
<p>ABOUT THE SERVICE&nbsp;</p>
<p>The Service allows you to register, view and receive online information regarding your membership</p>
<p>REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE</p>
<p>You need to be at least 18 years old to register for and use the Service, or otherwise your guardian or leader should register you as a member upon your acceptance and willingly consent in your respective branch. Joining any fellowship/programs administered by TAYOMI such as CASFETA, BCF or CLPD, means a consent to be registered as a member. Guardians and leaders are responsible for accounts of members they register on behalf.</p>
<p>If you are a user who signs up for the Service, you will create a personalized account which includes a unique username and a password to access the Service and to receive messages from the Organization. You agree to notify us immediately of any unauthorized use of your password and/or account. The Organization will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your member name, password and/or account.</p>
<p><strong>USE RESTRICTIONS</strong></p>
<p>Your permission to use the Site is conditioned upon the following use, posting and conduct restrictions:</p>
<p><strong>You agree that you will not under any circumstances:</strong></p>
<p>Access the Service for any reason other than your personal, non-commercial use solely as permitted by the normal functionality of the Service, collect or harvest any personal data of any user of the Site or the service without our explicit written permission.</p>
<p>Use the Site or the Service for the solicitation of business in the course of trade or in connection with a commercial enterprise, distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly available searchable indices but retain the right to revoke this permission at any time on a general or specific basis);</p>
<p>use the Service for any unlawful purpose or for the promotion of illegal activities; attempt to, or harass, abuse or harm another person or group; use another user&rsquo;s account without permission; intentionally allow another user to access your account; provide false or inaccurate information when registering an account;</p>
<p><strong>Interfere or attempt to interfere with the proper functioning of the Service;</strong></p>
<p>Make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure; bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data; circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service; or publish or link to malicious content of any sort, including that intended to damage or disrupt another user&rsquo;s browser or computer.</p>
<p><strong>POSTING AND CONDUCT RESTRICTIONS</strong></p>
<p>When you create your own personalized account, you may be able to provide your full name, phone number, contact information, email and physical address (Country, City, District, Ward, and Street (&ldquo;User Content&rdquo;) to the Service. You are solely responsible for the User Content that you, provide, link to or otherwise make available via the Service.</p>
<p>You agree that we are collecting your details for the purpose of utilizing them during your activeness in the organisation as a member. The Organization, however, reserves the right to remove any user content from the service upon confirming you are no longer a member according to the organisation constitution.</p>
<p>We grant you permission to use and access the Service, subject to the following express conditions surrounding User Content. You agree that failure to adhere to any of these conditions constitutes a material breach of these Terms.</p>
<p>By transmitting and submitting any User Content while using the Service, you agree as follows:</p>
<p>You are solely responsible for your account and the activity that occurs while signed into or while using your account; you will not register information that is malicious, libellous, false or inaccurate;</p>
<p>You hereby agree that we have the right to determine whether your User registration submissions are appropriate and comply with these Terms of Service, remove any and/or all of your submissions, and terminate your account and withdraw your membership with or without prior notice.</p>
<p>You understand and agree that any liability, loss or damage that occurs as a result of the use of any User Content that you make available or access through your use of the Service is solely your responsibility. The Site is not responsible for any public display or misuse of your User Content.</p>
<p>The Site does not, and cannot, pre-screen or monitor all User Content. However, at our discretion, we, or technology we employ, may monitor and/or record your interactions with the Service or with other Users.</p>
<p>TAYOMI MEMBERSHIP AND MEMBERSHIP MANAGEMENT SYSTEM ACCOUNT ACCESS</p>
<p>The term Pay&nbsp;on TAYOMI Membership Management System means willingly giving the assigned amount of money as annual membership fees, conference contribution, and other voluntary offerings as may be directed by the organization. Your membership status will remain active once you have paid your dues and fulfil other requirements according to organization&rsquo;s constitution and bylaws.</p>
<p>REFUND POLICY</p>
<p>Thanks for your offering though TAYOMI Membership Management System. Due to the digital nature of our service, and faith based nature of our organization we do not accept refund requests, any member whose account or membership is terminated will not be refunded any offering or contribution paid.</p>
<p>MEMBERS ONLINE CONTENT DISCLAIMER</p>
<p>Opinions, advice, statements, offers, or other information or content made available through the Service, but not directly by the Site, are those of their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content.</p>
<p>We do not guarantee the accuracy, completeness, or usefulness of any information on the Site or the Service nor do we adopt nor endorse, nor are we responsible for, the accuracy or reliability of any opinion, advice, or statement made by other parties. We take no responsibility and assume no liability for any User Content that you or any other user or third party posts or sends via the Service. Under no circumstances will we be responsible for any loss or damage resulting from anyone&rsquo;s reliance on information or other content registered on the Service, or transmitted to users.</p>
<p>Though we strive to enforce these Terms of Use, you may be exposed to User Content that is inaccurate or objectionable when you use or access the Site or the Service. We reserve the right, but have no obligation, to monitor the materials registered in the public areas of the Site or the Service or to limit or deny a user&rsquo;s access to the Service or take other appropriate action if a user violates these Terms of Use or engages in any activity that violates the rights of any person or entity or which we deem unlawful, offensive, abusive, harmful or malicious. E-mails sent between you and other participants that are not readily accessible to the general public will be treated by us as private to the extent required by applicable laws. The Organization shall have the right to remove any material that in its sole opinion violates or is alleged to violate, the law or this agreement or which might be offensive, or that might violate the rights, harm, or threaten the safety of users or others. Unauthorized use may result in criminal and/or civil prosecution under local law. If you become aware of misuse of our Service or violation of these Terms of Use, please contact us through <a href="mailto:info@tayomi.or.tz">info@tayomi.or.tz</a></p>
<p>EMAIL MAY BE USED TO PROVIDE NOTICE</p>
<p>Communications made through the Service&rsquo;s email and messaging system will constitute legal notice to the Site, the Service, or any of its officers, employees, agents or representatives in any situation where legal notice is required by contract or any law or regulation.</p>
<p>USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM</p>
<p>For contractual purposes, you: (a) consent to receive communications from us in an electronic form via the email address you have submitted; and (b) agree that all Terms of Use, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. The foregoing does not affect your non-waivable rights.</p>
<p>We may also use your email address to send you other messages, including information about the Site or the Service and special contributions. You may opt-out of such email by changing your account settings, using the &ldquo;Unsubscribe&rdquo; link in the message, or by sending an email to <a href="mailto:info@tayomi.or.tz">info@tayomi.or.tz</a></p>
<p>Opting out may prevent you from receiving messages regarding the Site, the Service or special contributions to the organisation.</p>
<p>WARRANTY DISCLAIMER</p>
<p>THE SERVICE, IS PROVIDED &ldquo;AS IS,&rdquo; WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SERVICE INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY, AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USE OF FILES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED FROM THE SERVICE.</p>
<p>LIMITATION OF DAMAGES; RELEASE</p>
<p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SITE, THE SERVICE, ITS AFFILIATES, TRUSTEES, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM: (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER ACCOUNT; (B) YOUR USE OR INABILITY TO USE THE SERVICE; (C) THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH USE OR WITH ANY OTHER USER OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
<p>MODIFICATION OF TERMS OF USE</p>
<p>We can amend these Terms of Use at any time and will update these Terms of Use in the event of any such amendments. It is your sole responsibility to check the Site from time to time to view any such changes in this agreement. Your continued use of the Site or the Service signifies your agreement to our revisions to these Terms of Use. We will endeavour to notify you of material changes to the Terms by posting a notice on our homepage and/or sending an email to the email address you provided to us upon registration. For this additional reason, you should keep your contact and profile information current. Any changes to these Terms (other than as set forth in this paragraph) or waiver of our rights hereunder shall not be valid or effective except in a written agreement bearing the physical signature of one of our officers. No purported waiver or modification of this agreement on our part via telephonic or email communications shall be valid.</p>
<p>GENERAL TERMS</p>
<p>If any part of this Terms of Use agreement is held or found to be invalid or unenforceable, that portion of the agreement will be construed as to be consistent with applicable law while the remaining portions of the agreement will remain in full force and effect. Any failure on our part to enforce any provision of this agreement will not be considered a waiver of our right to enforce such provision. Our rights under this agreement survive any transfer or termination of this agreement.</p>
<p>You agree that any cause of action related to or arising out of your relationship with the Organization must commence within ONE year after the cause of action accrues. Otherwise, such cause of action is permanently barred.</p>
<p>These Terms of Use and your use of the Site are governed by the laws of the United Republic of Tanzania without regard to conflict of law provisions.</p>
<p>We may assign or delegate these Terms of Service and/or our Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without our prior written consent, and any unauthorized assignment or delegation by you is void.</p>
<p>YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS AND CONDITIONS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER WITH THE PRIVACY POLICY AT <a href="http://mms.tayomi.or.tz">http://mms.tayomi.or.tz</a> AND <a href="http://www.tayomi.or.tz">www.tayomi.or.tz</a>, REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.</p>
<p>Last Updated: February 7, 2021</p>`

export default function TnC(props) {
    const [terms, setTerms] = useState(html)
    useEffect(() => {
        async function fechData() {
            const res = await requests.get(url.tnc)
            setTerms(res)
        }
        fechData();
    }, [])
    return <Container>
        <div dangerouslySetInnerHTML={{ __html: terms }}></div>
    </Container>
}