import React from 'react';
import AddItem from "components/AddItem";
import { url, utils } from "helpers";

export default function Payment(props) {
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff;
  const nonModal = true;
  const displayElement = true;

  const config = {
    itemType: "payments.payment",
    itemType_verbose: "payment",
    permissionsTitle: "Share Access",
    // attachmentQuery:{advanced_search:"fees__id:{id}"},
    columns: [
      {
        type: "decimal",
        id: "amount",
        name: "amount",
        title: "Amount",
        placeholder: "Enter Amount",
        offset: 1,
        required:true,
        col: nonModal ? 5 : 10,
      },
      {
        type: "text",
        id: "note",
        name: "note",
        title: "Note",
        placeholder: "Leave a note",
        offset: 1,
        col: nonModal ? 5 : 10,
      },
    ].filter(v => v)
  }
  return <AddItem {...props} config={config} />
}


