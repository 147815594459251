import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Spinner, Alert, Label, Modal, ModalHeader, Table } from "reactstrap"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import Creatable from 'react-select/creatable'
import { AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import { User } from 'pages/User/User';
import { requests, url, utils} from 'helpers';

export function RenderTable(props) {
    const { columns = [], title = "Title", 
    excludeFirstRow = false, itemURL, 
    isGroup, onSubmit, canDelete,
} = props;
    const [alertMessage, setAlertMessage] = useState()
    const [alerType, setAlertType] = useState("success")
    const [values, setValues] = useState(props.values || []);
    const [loggedUser, setLoggedUser] = useState(utils.getUser())

    function setMessage(message, type){
        setAlertType(type)
        setAlertMessage(message);
    }

    async function removeAccess(e, index) {
        try {
            if(!isGroup && loggedUser?.id === e[0]){
                return setMessage("Sadly you cannot remove yourself. This is to prevent people losing access to item only them has access to",
                "warning")
            }
            await requests.post(itemURL + "remove_perm/", {
                [isGroup ? `group__id` : `user__id`]: `${e[0]}`,
                permission__codename: e[2]
            });
            onSubmit && onSubmit();
            setValues(
                values.filter((v, i) => i !== index)
            )
            setMessage(`${e[1]} was removed successfully`, "info")
        } catch (error) {
            alert(error.message)
        }
    }

    return (
        <div>
            <h4>{title}</h4>
            {alertMessage && <Alert color={alerType}>{alertMessage}</Alert>}
            <Table striped>
                {columns.length > 0 && <thead>
                    <tr>
                        {columns.map(col => (<th>{col}</th>))}
                        {canDelete && <th>
                            <span>Action</span>
                        </th>}
                    </tr>
                </thead>}
                <tbody>
                    {values.map((value, index) =>
                        <tr>
                            {value?.slice && value.slice(excludeFirstRow ? 1 : 0, value.length).map((e, index) => <td>{e}</td>)}
                            {canDelete && <td className="alert-warning">
                                <Link onClick={() => removeAccess(value, index)}>
                                    Remove
                                </Link>
                            </td>}
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}


export const Permission = (props = {}) => {
    const { setDisplayModal = () => { }, setSearch = () => { }, setJson = () => { }, config } = props;
    const history = useHistory();
    const { dataId, displayElement = true, nonModal = true, permissionObject = "", itemURL } = props.match.params;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");
    const [failed, setFailed] = useState(null);
    const [dataJson, setDataJson] = useState({
        permission__codename: [],
        user__id: [],
        group__id: [],
    });
    const [permissions, setPermissions] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [permittedUsers, setPermittedUsers] = useState({});
    const [displayUserModal, setDisplayUserModal] = useState(false);
    const [userSearch, setUserSearch] = useState("");
    const [groupSearch, setGroupSearch] = useState("");

    const getUsers = useCallback(
        _.debounce((params) => {
            requests.get(url.user, params)
                .then(data => {
                    const options = data.results.map(d => ({
                        label: `${d.first_name} ${d.last_name}`, value: d.id
                    }))
                    setUsers(options);
                }).catch(error => {
                    console.log("Error loading users ", error.message)
                })
        }, 1000),
        []
    );
    const getGroups = useCallback(
        _.debounce((params) => {
            requests.get(url.group, params)
                .then(data => {
                    const options = data.results.map(d => ({
                        label: `${d.name}`, value: d.id
                    }))
                    setGroups(options);
                }).catch(error => {
                    console.log("Error loading group ", error.message)
                })
        }, 1000),
        []
    );

    useEffect(() => {
        requests.get(itemURL + "permitted_users")
            .then(res => {
                setPermittedUsers(res);
            }).catch(error => {
                console.log("Errorg getting permissted users")
            })
    }, [])

    useEffect(() => {
        getUsers({ search: userSearch, page: 1, size: 100 });
    }, [userSearch]);

    useEffect(() => {
        getGroups({ search: groupSearch, page: 1, size: 100 });
    }, [groupSearch]);


    useEffect(() => {
        // get the item details here with permissions included
        requests.get(itemURL)
            .then(data => {
                const options = data.permissions.map(d => ({
                    label: d.replace("_", " "), value: d
                }));
                setPermissions(options);
                let permissionData = dataJson.user__id;
                const userData = { label: `${data.user?.first_name || ""} ${data.user?.last_name || ""}`, value: data.user?.id || 0 };
                (permissionData.filter(d => d.value === data.user?.id).length === 0 && data.user !== null) ? permissionData.push(userData) : "";
                setDataJson({ ...dataJson, user__id: permissionData });

            }).catch(error => {
                console.log("Error loading members ", error.message)
            })
    }, [submitted, dataId]);

    const submitData = (e) => {
        e.preventDefault();
        setFailed(null);
        setLoading(true);

        const json = {
            permission__codename: dataJson.permission__codename.join(","),
            user__id: dataJson?.user__id.map(i => i.value).join(","),
            group__id: dataJson?.group__id.map(i => i.value).join(","),
        }

        requests.post(itemURL + "assign_perm/", json)
            .then(res => {
                setDisplayModal(false);
                setLoading(false);
                setFailed(false);
                setAlert("Permissions added successfully!");
                // (props?.modalData || history.push("/permissions"));
            }).catch(error => {
                setDisplayModal(false);
                setLoading(false);
                setFailed(true);
                setAlert(error.message);
            });
    }

    const userFilter = (option, inputValue) => {
        setUserSearch(inputValue.toLowerCase());
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }
    const groupFilter = (option, inputValue) => {
        setGroupSearch(inputValue.toLowerCase());
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }

    const newUserModal = () => {
        setDisplayUserModal(true);
    }

    // inf user has delete access give them remove access
    const canRemoveAccess = permissions.map(p=>p.value).join(" ").includes("delete")

    return (
        <React.Fragment>
            <div className={displayElement ? "container-fluid" : "container-fluid"}>
                {displayElement &&
                    <MetaTags>
                        <title>{+dataId === 0 ? "New" : "Edit"} | {permissionObject} Permissions</title>
                    </MetaTags>
                }
                <Container fluid={displayElement} className={displayElement ? "" : "p-0"}>
                    {displayElement && <Breadcrumbs title={+dataId === 0 ? "New" : "Edit"} breadcrumbItem={`${permissionObject} Permissions`} />}
                    <Row>
                        <Col xs={12} sm={12} lg="12">
                            <Card className={displayElement ? "" : "p-0"}>
                                <CardBody className={displayElement ? "" : "p-0"}>
                                    <div className={displayElement ? "p-2" : ""}>
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={submitData}>
                                            {failed === false && (<Alert color="success">{alert}</Alert>)}
                                            {failed === true && (<Alert color="danger">{alert}</Alert>)}
                                            <div className='justify-content m-3'>
                                                {permittedUsers?.users?.results?.length ?
                                                    <RenderTable
                                                        title="Users with Access"
                                                        columns={["Name", "Permissions"]}
                                                        itemURL={itemURL}
                                                        excludeFirstRow={true}
                                                        isGroup={false}
                                                        // onSubmit={() => setDisplayModal(false)}
                                                        canDelete={canRemoveAccess}
                                                        values={permittedUsers?.users?.results?.map(user => ([user?.item?.id, `${user.item.first_name} ${user.item.last_name} (${user.item.username})`, user.permissions.join(", ")]))}
                                                    /> : ""}
                                                {permittedUsers?.groups?.results?.length ?
                                                    <RenderTable
                                                        title="Groups with Access"
                                                        columns={["Name", "Permissions"]}
                                                        itemURL={itemURL}
                                                        isGroup={true}
                                                        excludeFirstRow={true}
                                                        canDelete={canRemoveAccess}
                                                        // onSubmit={() => setDisplayModal(false)}
                                                        values={permittedUsers?.groups?.results?.map(row => ([row.item.id, row.item.name, row.permissions.join(", ")]))}
                                                    /> : ""}

                                            </div>
                                            {loading && (<div className="mb-3 text-center"><Spinner className="ms-2" color="primary" /></div>)}
                                            <>
                                                <Row>
                                                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className="offset-md-1 offset-lg-1 offset-xl-1">
                                                        <div className="mb-2">
                                                            <Label>Users</Label>
                                                            <Creatable
                                                                inputId="user"
                                                                name="user"
                                                                value={dataJson.user__id}
                                                                isMulti={true}
                                                                isClearable={true} onChange={(e) => setDataJson({ ...dataJson, user__id: e })}
                                                                options={users} classNamePrefix={nonModal ? "select2-selection" : ""}
                                                                filterOption={userFilter} getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.value}
                                                                noOptionsMessage={(d) => "Search to add new User"}
                                                                onCreateOption={newUserModal} styles={nonModal ? { menu: styles => ({ ...styles, position: 'absolute', zIndex: 1000 }) } : ""}
                                                                menuPortalTarget={nonModal ? document.querySelector('body') : ""}
                                                                appendTo={document.body}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            <>
                                                {!config?.hideGroups && <Row>
                                                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className="offset-md-1 offset-lg-1 offset-xl-1">
                                                        <div className="mb-2">
                                                            <Label>Groups</Label>
                                                            <Creatable
                                                                inputId="group"
                                                                name="group"
                                                                value={dataJson.group__id}
                                                                isMulti={true}
                                                                isClearable={true} 
                                                                onChange={(e) => setDataJson({ ...dataJson, group__id: e })}
                                                                options={groups} 
                                                                classNamePrefix={nonModal ? "select2-selection" : ""}
                                                                filterOption={groupFilter} 
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.value}
                                                                noOptionsMessage={(d) => "Search to add new group"}
                                                                // onCreateOption={newUserModal} 
                                                                styles={nonModal ? { menu: styles => ({ ...styles, position: 'absolute', zIndex: 1000 }) } : ""}
                                                                menuPortalTarget={nonModal ? document.querySelector('body') : ""}
                                                                appendTo={document.body}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>}
                                                {permissions?.length > 0 && <Row>
                                                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className="offset-md-1 offset-lg-1 offset-xl-1">
                                                        <div className="mb-2">
                                                            <Label>Permissions</Label>
                                                            <DualListBox
                                                                options={permissions}
                                                                selected={dataJson.permission__codename}
                                                                onChange={(e) => setDataJson({ ...dataJson, permission__codename: e })}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>}
                                            </>
                                            <div className="col-4 offset-4 mt-3 d-grid">
                                                <button className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" type="submit">Submit</button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={displayUserModal} role="dialog" autoFocus={true} centered={true} tabIndex="-1" data-toggle="modal" toggle={() => { setDisplayUserModal(!displayUserModal) }}>
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <ModalHeader toggle={() => { setDisplayUserModal(!displayUserModal) }}>New User</ModalHeader>
                        </div>
                        <User
                            setSearch={setUserSearch}
                            setDisplayModal={setDisplayUserModal}
                            setJson={setDataJson} dataJson={dataJson}
                            match={{ params: { dataId: '0', displayElement: false, nonModal: false } }}
                            modalData={"user"}
                        />
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}