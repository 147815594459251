import React, { useEffect, useState } from "react"
import Table from "components/Table";
import {utils, url} from "helpers";

export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Member Type",
        field: "member_type.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Region",
        field: "district.region.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "District",
        field: "district.name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Grades(props){
    const loggedUser = utils.getUser()
    const advancedSearch ={
        itemType:"branch",
        itemType_verbose:"Branch",
        // permissionsTitle:"Share Request",
        columns:[
          {   
            type: "text", 
            id: "name", 
            name: "name", 
            title: "Name", 
            placeholder: "Enter Name", 
            offset: 1, 
            required:false,
            col:5
        },
        {   
          type: "text", 
          id: "code", 
          name: "code", 
          required:false,
          title: "Code", 
          placeholder: "Enter Code", 
          offset: 1, 
          col:5
      },

      {
            type: "select-filter",
            id: "region__id", 
            name: "district__region__id", 
            title: "Region", 
            options: [], 
            placeholder: "Choose region", 
            valueField:"district.region.id",
            optionsURL:url.region,
            getLabel:(item)=>`${item.name}`,
            noOptionsMessage: "Search Region", 
            append: document.body, 
            offset: 1, 
            col:5
        },
            {
                type: "select-filter",
                id: "district", 
                name: "district__id", 
                title: "District", 
                options: [], 
                placeholder: "Choose district", 
                valueField:"district.id",
                optionsURL:url.district,
                getLabel:(item)=>`${item.name}`,
                noOptionsMessage: "Add New District", 
                append: document.body, 
                getOptionsParams:(state)=>({region__id:state.district__region__id}),
                offset: 1, 
                col:5
            },

            {
                type: "select-filter",
                id: "member_type__id", 
                name: "member_type__id", 
                title: "Member Type", 
                options: [], 
                placeholder: "Choose Type", 
                optionsURL:url.membertype,
                append: document.body, 
                offset: 1, 
                col:5
            },

            {
                type: "select-filter",
                id: "branch_types__id", 
                name: "branch_types__id", 
                title: "Branch Type", 
                options: [], 
                placeholder: "Choose Type", 
                optionsURL:url.branchtype,
                append: document.body, 
                offset: 1, 
                col:5
            },

        ].filter(d=>d)
    }

    return <Table 
            columns={columns}
            itemType = "branch"
            itemType_verbose="Branch"
            advancedSearch={advancedSearch}
    /> 
}