import React, { useState, useEffect, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
  Modal,
  ModalHeader,
  CardSubtitle,
  Button,
} from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import FormAttributes from "utilities/FormAttributes";
import Attachment from "components/Attachment";
import { url, requests, utils } from "helpers"
import { Permission } from "pages/User/Permission"

export default function AddItem(props) {
  const { config, onDataJsonChange , onSubmit} = props
  const { itemType, title, canEdit, attachmentQuery, userNotRequired} = config;
  const {
    setDisplayModal = () => { },
    setSearch = () => { },
    setJson = () => { },
  } = props
  const { dataId="0", displayElement = true, nonModal = false } = props.match?.params||{};
  const loggedUser = utils.getUser();
  const [dataJson, setDataJson] = useState(props.dataJson||utils.getSearchParams(window.location.href))
  const nextPage = dataJson.nextPage||props.nextPage||`/${itemType?.replace(".", "/")}s`
  const isEditing = parseInt(dataId) !== 0
  const history = useHistory();
  const itemType_verbose = config.itemType_verbose || config.itemType;
  // const [isShowing, setIsShowing] = useState(true);
  const [columns, setColumns] = useState(props.config.columns);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [failed, setFailed] = useState(null);
  
  const [submitted, setSubmitted] = useState(false);
  const [item, setItem] = useState();
  const [displayPermissionModal, setDisplayPermissionModal] = useState(false);
  const [displayAttachmentModal, setDisplayAttachmentModal] = useState(false)
  const [_canEdit, set_canEdit] = useState(false);

  useEffect(()=>{
    onDataJsonChange && onDataJsonChange(dataJson)
  },[dataJson])

  useEffect(() => {
    const edit = canEdit ? canEdit({ item, values: dataJson }) : (item ? item.permissions?.includes(`change_${itemType.split(".").slice(-1)[0]}`) : true)
    set_canEdit(
      edit
    )
  }, [item])

  function formatDataJson(d) {
    props.config.columns.map(col => {
      const key = col.name
      let value = d[key];
      if (col.formatValueBeforeSubmit) {
        value = col.formatValueBeforeSubmit(d[key], item)
      }
      if(value === null){ //change all null values to undefined
        value = undefined
      }
      d[key]=value
    })
    return d
  }

  function updateDataJsonFromItem(_item) {
    if (!_item) _item = item
    let obj = {}
    columns.map(col => {
      const value = col.setValue
        ? col.setValue(_item)
        : utils.getObject(_item, col.valueField || col.name)
      obj[col.name] = value
    })
    setDataJson(obj)
  }

  useEffect(() => {
    // get item data for editing
    if (isEditing) {
      requests
        .get(url.getURL(itemType, { type: "view", item: { id: dataId } }))
        .then(data => {
          setItem(data);
          updateDataJsonFromItem(data);
        })
        .catch(error => {
          console.log("Error fetching data details: " + error.message)
        })
    }
  }, [submitted, dataId])

  useEffect(() => {
    // update to determine which fields to hide
    const newColumns = [];
    [...props.config.columns].map(col => {
      const value = col.setValue
        ? col.setValue(item)
        : utils.getObject(item, col.valueField || col.name)
      col.value = value
      col.readOnly = (canEdit
        ? !canEdit({ item, values: dataJson })
        : !(col.canEdit ? col.canEdit({ item, values: dataJson }) : _canEdit)
      );

      newColumns.push(col)
    })
    setColumns(
      newColumns.filter(col =>
        col.canShow ? col.canShow({ item, values: dataJson }) : true
      )
    )
  }, [dataJson, item, _canEdit])

  const submitData = e => {
    e.preventDefault()
    setFailed(null)
    setLoading(true)
    const data = formatDataJson({ ...dataJson });
    if (!data.user && !userNotRequired) data.user = loggedUser.id;
    if(onSubmit) return onSubmit(data)
    requests
      .post(
        url.getURL(itemType, { type: "add" }),
        data
      )
      .then(response => {
        setSearch(dataJson.name)
        setDisplayModal(false)
        setJson({ ...(props?.dataJson || {}), [props?.modalData]: response.id })
        setLoading(false)
        setFailed(false)
        setAlert(`${itemType} added successfully!`)
        props?.modalData || history.push(nextPage)
      })
      .catch(error => {
        setLoading(false)
        setFailed(true)
        setAlert(JSON.stringify(error.data || error.message))
        // setAlert(error.message);
      })
  }

  const editData = e => {
    e.preventDefault()
    setFailed(null)
    setLoading(true)
    requests
      .patch(
        url.getURL(itemType, { type: "view", item: { id: dataId } }),
        formatDataJson({ ...dataJson })
      )
      .then(response => {
        setLoading(false)
        setFailed(false)
        setAlert(`${itemType} updated successfully!`)
        history.push(nextPage)
        setSubmitted(!submitted)
      })
      .catch(error => {
        setLoading(false)
        setFailed(true);
        let errorMessage = JSON.stringify(error.data || error.message);
        setAlert(
          errorMessage.length > 2000 ? "Server Error. You probably messed up somewhere with the form. Consult IT support if needs help" : errorMessage
        )
      })
  }

  function toggleDisplayAttachmentModal() {
    setDisplayAttachmentModal(!displayAttachmentModal)
  }

  const itemURL = url.getURL(itemType, {
    type: "view",
    item: { id: dataId },
  });

  // console.log("item102", item?.id);

  return (
    <React.Fragment>
      <div className={displayElement ? "page-content" : ""}  style={{zIndex:1000}}>
        {displayElement && (
          <MetaTags>
            <title>{!isEditing ? "New" : "Edit"}</title>
          </MetaTags>
        )}
        <Container
          fluid={displayElement}
          className={displayElement ? "" : "p-0"}
        >
          {displayElement && (
            <Breadcrumbs
              title={!isEditing ? "New" : "Edit"}
              breadcrumbItem={itemType_verbose}
            />
          )}
          <Row>
            <Col xs={12} sm={12} lg="12">
              <Card className={displayElement ? "" : "p-0"}>
                <CardBody className={displayElement ? "" : "p-0"}>
                  <div className="col-md-6 offset-md-6 text-md-end text-lg-end">
                    {isEditing && (
                      <CardSubtitle className="mb-3 mt-2 text-end me-5">
                        {!!attachmentQuery && <Button
                          type="button"
                          onClick={() => setDisplayAttachmentModal(true)}
                          color="secondary"
                          className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1"
                        >
                          <i className="fa fa-file"></i> Manage Attachments
                        </Button>}

                        <Button
                          type="button"
                          onClick={() => setDisplayPermissionModal(true)}
                          color="primary"
                          className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1"
                        >
                          {config?.permissionsTitle ? (
                            config?.permissionsTitle
                          ) : (
                            <>
                              <i className="bx bx-share me-2" />
                              Share
                            </>
                          )}
                        </Button>
                      </CardSubtitle>
                    )}
                  </div>
                  <div className={displayElement ? "p-2" : ""}>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={e =>
                        !isEditing ? submitData(e) : editData(e)
                      }
                    >
                      {failed === false && (
                        <Alert color="success">{alert}</Alert>
                      )}
                      {failed === true && <Alert color="danger">{alert}</Alert>}
                      {loading && (
                        <div className="mb-3 text-center">
                          <Spinner className="ms-2" color="primary" />
                        </div>
                      )}
                      {true &&
                      <FormAttributes
                        attributes={columns}
                        dataJson={dataJson}
                        obj={item}
                        setDataJson={setDataJson}
                      />}
                      {_canEdit && <div className="col-4 offset-4 mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={displayAttachmentModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          size={"lg"}
          tabIndex="-1"
          data-toggle="modal"
          toggle={toggleDisplayAttachmentModal}
        >
          <div className="modal-content container-fluid">
            <div className="modal-header border-bottom-0">
              <ModalHeader
                toggle={toggleDisplayAttachmentModal}
              >
                Attachments
              </ModalHeader>
            </div>
            <Attachment
              attachmentQuery={{ ...attachmentQuery||{}, advanced_search: utils.replaceVariablesFromString(attachmentQuery?.advanced_search||"", item) }}
              itemURL={itemURL} onSubmit={toggleDisplayAttachmentModal}
            />

          </div>
        </Modal>

        <Modal
          isOpen={displayPermissionModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          size={"lg"}
          tabIndex="-1"
          data-toggle="modal"
          toggle={() => {
            setDisplayPermissionModal(!displayPermissionModal)
          }}
        >
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <ModalHeader
                toggle={() => {
                  setDisplayPermissionModal(!displayPermissionModal)
                }}
              >
                {config.permissionsTitle || `${title || itemType} Permissions`}
              </ModalHeader>
            </div>
            <Permission
              setDisplayModal={setDisplayPermissionModal}
              config={config}
              match={{
                params: {
                  dataId,
                  displayElement: false,
                  nonModal: false,
                  permissionObject: title,
                  itemURL,
                },
              }}
              modalData={"user"}
            />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
