import React from "react"
import Table from "components/Table";

export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Grades(props){
    return <Table 
            columns={columns}
            title="Categories"
            subtitle={"Helps arrange tasks in categories so it is easy to access them"}
            itemType = "todo.taskcategory"
            itemType_verbose="Task Category"
    /> 
}
