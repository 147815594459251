import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { utils } from "helpers";

const MonthlyEarning = (props) => {

  const [monthlyRate, setMonthlyRate] = useState(0);
  const [thisMonth, setThisMonth] = useState(0);
  const [lastMonth, setLastMonth] = useState(0);

  useEffect(() => {
    setThisMonth(props.dashboard?.payments?.this_month || 0)
    setLastMonth(props.dashboard?.payments?.last_month || 1)
  }, [props.dashboard])

  const getMonthlyRate = () => {
    const rate = ((parseInt(thisMonth) - parseInt(lastMonth)) / (parseInt(thisMonth || 1))) * 100;
    setMonthlyRate(parseInt(rate) === -100 ? 0 : rate);
  }

  useEffect(() => {
    getMonthlyRate();
  }, [thisMonth, lastMonth]);

  const isUp = monthlyRate > 0
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Monthly Comparison</CardTitle>
          <Row>
            <Col sm="12">
              <p className="text-muted">This month</p>
              <h3>TZS {utils.formatNumber(thisMonth)}</h3>
              <p className="text-muted">
                <span className={`text-${isUp ? "success" : "warning"} me-2`}>
                  {" "} {monthlyRate > 1000 ? "1000%+" : `${utils.formatNumber(monthlyRate, 2)}%`} <i className={`mdi mdi-arrow-${isUp ? "up" : "down"}`}></i>{" "}
                </span>{" "}
                From previous period
              </p>
              <div className="mt-4">
                <Link to="/payments/transactions" className="btn btn-primary waves-effect waves-light btn-sm">View More<i className="mdi mdi-arrow-right ms-1"></i></Link>
              </div>
            </Col>
            {/* <Col sm="6">
              <div className="mt-4 mt-sm-0">
                <ApexRadial monthlyRate={monthlyRate>1000?100:monthlyRate} />
              </div>
            </Col> */}
          </Row>
          {/* <p className="text-muted mb-0">
            In Jesus we both grow and prosper
          </p> */}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
