import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// Redux
import { Link, useHistory } from "react-router-dom"
import PasswordStrengthBar from "react-password-strength-bar";
import { requests, setAuthorization, url, utils } from "helpers";

const Register = props => {
  const [dataJson, setDataJson] = useState({ username: "", email: "", password: "", confirm: "", first_name: "", last_name: "" });
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [alert, setAlert] = useState("")
  const [failed, setFailed] = useState(null)
  const [hideForm, setHideForm] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [authJson, setAuthJson] = useState({ accessToken: "", uid: "", username: "", password: "", email: "", role: "admin", permissions: [] })
  const history = useHistory()

  const goDashboard = () => {
    history.push(url.routes.profile)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage) {
      setFailed(true);
      setAlert(errorMessage);
    }else{
      setFailed(null);
      setLoading(true);
      //Remove email since backed is not currently set to send email
      requests.post(url.register, { ...dataJson, email: undefined, confirm: undefined })
        .then(response => {
          getUserDetails(response.key)
        }).catch(error => {
          setLoading(false)
          setFailed(true)
          let msg = `${error.data}`.includes("IntegrityError")?"Sorry this username is taken":error.message
          setAlert("Failed to create account! "+msg)
        });
      }
  }

  const getUserDetails = (token) => {
    setAuthorization(token);
    requests.get(url.authuser)
      .then(response => {
        let fullName = response.first_name + " " + response.last_name;
        if (fullName.replace(/\s+/g, '') === "") {
          fullName = dataJson.username;
        }
        utils.setUser({
          ...response,
          token,
          email: dataJson.email,
          fullName
        })
        // now path the email
        requests.patch(url.getURL("user", {item:response, type:"detail"}), {email:dataJson.email})
        goDashboard();
      }).catch(error => {
        setLoading(false)
        setFailed(true);
        setAlert("Failed to fetch user details" + ". " + JSON.stringify(error.message));
      });
  }

  function isUserNameValid(val){
    /* 
      Usernames can only have: 
      - Lowercase Letters (a-z) 
      - Numbers (0-9)
      - Dots (.)
      - Underscores (_)
    */
    let error = undefined;
    const res = /^[a-z0-9A-Z_\.]+$/.exec(val);
    let valid = !!res;
    if (!valid) {
      error = "Username can only have either letters, number, dot or underscore"
    }
    return { valid, error }
  }

  function updateUsername(val) {
    const { valid, error } = isUserNameValid(val)
    if (valid) {
      setDataJson({
        ...dataJson,
        username: val
      })
    }
    setErrorMessage(error)
  }


  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Tayomi</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Get your Tayomi account now.</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-3">
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <AvForm className="form-horizontal" onValidSubmit={(e) => { handleSubmit(e) }}>
                      {props.user && props.user ? (
                        <Alert color="success">Register User Successfully</Alert>
                      ) : null}

                      {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{props.registrationError}</Alert>
                      ) : null}
                      <div className="mb-2">
                        <Row>
                          <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <AvField id="first_name" name="first_name" value={dataJson.first_name} onChange={(e) => setDataJson({ ...dataJson, first_name: e.target.value })} label="First Name" className="form-control" placeholder="Enter first name" type="text" required />
                          </Col>
                          <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <AvField id="last_name" name="last_name" value={dataJson.last_name} onChange={(e) => setDataJson({ ...dataJson, last_name: e.target.value })} label="Last Name" className="form-control" placeholder="Enter last name" type="text" required />
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-2">
                        <AvField
                          id="username" name="username" value={dataJson.username}
                          onChange={(e) => updateUsername(e.target.value)}
                          label="Username"
                          maxLength={12}
                          className="form-control"
                          placeholder="Enter Username" type="text" required
                          errorMessage={errorMessage}
                        />
                        {errorMessage && <Alert className='mt-2 p-1 px-2' color="danger">{errorMessage}</Alert>}
                      </div>

                      <div className="mb-2">
                        <AvField id="email" name="email" value={dataJson.email}
                          onChange={(e) => setDataJson({ ...dataJson, [e.target.name]: e.target.value })}
                          label="Email Address" className="form-control" placeholder="Enter email" type="email"
                        />
                      </div>

                      <div className="mb-2">
                        <Row>
                          <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <AvField id="password" name="password" value={dataJson.password} onChange={(e) => setDataJson({ ...dataJson, password: e.target.value })} label="Password" type="password" className="form-control" required placeholder="Enter Password" />
                            {dataJson?.password?.length > 0 && <PasswordStrengthBar password={dataJson.password} scoreWordStyle={{ fontSize: "11px" }} />}
                          </Col>
                          <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <AvField id="confirm" name="confirm" value={dataJson.confirm} onChange={(e) => setDataJson({ ...dataJson, [e.target.name]: e.target.value })} label="Confirm Password" type="password" className="form-control" required placeholder="Confirm Password" />
                            {dataJson?.confirm?.length > 0 && <PasswordStrengthBar password={dataJson.confirm} scoreWordStyle={{ fontSize: "11px" }} />}
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-4 d-grid">
                      {!!alert ? (
                        <Alert color="danger">{alert}</Alert>
                      ) : null}
                        <button
                          disabled={loading}
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit">{loading ? "Loading.. " : "Register"}</button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Tayomi{" "}
                          <Link to={url.routes.tnc} className="text-primary">Terms of Use</Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to={url.routes.login} className="font-weight-medium text-primary">{" "}Login</Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} TAYOMI
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Register.propTypes = {
//   registerUser: PropTypes.func,
//   registerUserFailed: PropTypes.func,
//   registrationError: PropTypes.any,
//   user: PropTypes.any,
// }

// const mapStatetoProps = state => {
//   const { user, registrationError, loading } = state.Account
//   return { user, registrationError, loading }
// }

// export default connect(mapStatetoProps, {
//   registerUser,
//   apiError,
//   registerUserFailed,
// })(Register)

export default Register
