import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { getTimeAmount, getShortString } from "utilities/TayomiUtil"
import { requests, url } from "helpers"

const NotificationDropdown = props => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [taskCount, setTaskCount] = useState(0);

    const size = 5
    const fetchData = () => {
        requests.get(url.todo.task, { status: 'pending', page: 1, size })
            .then(data => {
                setTaskCount(data.count);
                const dataContent = data.results.map(d => ({
                    ...d,
                    name: getShortString(d.name, 20),
                    status: d.status,
                    description: getShortString(d.description, 30),
                    duration: getTimeAmount(d.created)
                }));
                setTasks(dataContent);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
                <DropdownToggle className="btn header-item noti-icon waves-effect" tag="button" id="page-header-notifications-dropdown">
                    <i className="bx bx-bell bx-tada" />
                    <span className="badge bg-danger rounded-pill">{taskCount}</span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-right">
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col><h6 className="m-0"> {props.t("Tasks")} </h6></Col>
                            <div className="col-auto"><Link to={url.routes.tasks} className="small">{" "}View All</Link></div>
                        </Row>
                    </div>

                    <SimpleBar style={{ height: "230px" }}>
                        {tasks.map((item, index) => (
                            <Link to={url.routes.get(`${!item.permissions.includes("change_task") ? "tasks" : "task"}`, item)} className="text-reset notification-item" key={index}>
                                <div className="media">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title bg-primary rounded-circle font-size-16"><i className="bx bx-task" /></span>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mt-0 mb-1">{item.name}</h6>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">{item.description}</p>
                                            <p className="mb-0"><i className="mdi mdi-clock-outline" />{" "}{item.duration}{" ago"}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {tasks?.length === 0 && <p className="text-center pt-4">Your notifications will appear here.</p>}
                    </SimpleBar>
                    {taskCount > size && <div className="p-2 border-top d-grid">
                        <Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to={url.routes.tasks}><i className="mdi mdi-arrow-right-circle me-1"></i>{" "}{props.t("View More")}{" "}</Link>
                    </div>}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
    t: PropTypes.any
}