import React, { useEffect, useState } from "react"
import Table from "components/Table";
export const columns = [
    {
        label: "First Name",
        field: "first_name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Last Name",
        field: "last_name",
        sort: "asc",
        width: 250,
    },
    {
        label: "email",
        field: "email",
        sort: "asc",
        width: 250,
    },
    {
        label: "username",
        field: "username",
        sort: "asc",
        width: 250,
    },
   
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
        canEdit:true,
        canDelete:true,
    },
];


export default function Users(props){
    return <Table 
            {...props}
            columns={columns}
            itemType = "user"
            filter={Object}
            itemType_verbose="User"
    />
    
}
