export const API_BASE_URL = 'https://mmsapi.tayomi.or.tz';
// export const API_BASE_URL = 'http://instadalali.com:8001';
export const CORE_MODULE = API_BASE_URL + "/api/v1/";
export const PAYMENT_MODULE = API_BASE_URL + "/payments/api/v1/";
export const TODO_MODULE = API_BASE_URL + "/todo/api/v1/";
export const ACCESS_TOKEN = 'authUser';
export const PARTNER_INFO = 'partnerInfo';
export const USER_INFO = 'userInfo';
export const FULL_NAME = 'fullName';
export const USER_ID = 'userId';
export const CONTENT_IDS = {
    member_type: {
        "2": {
            "id": 1,
            "name": "BCF",
            "description": "All PRIMARY STUDENTS SHOULD BE REGISTERED UNDER THIS TYPE",
        },
        "1": {
            "id": 2,
            "name": "CASFETA",
            "description": "All SECONDARY AND HIGH LEARNING STUDENTS SHOULD BE REGISTERED UNDER THIS TYPE",
        },
        "3": {
            "id": 3,
            "name": "CLPD",
            "description": "ALL GRADUATES FROM CASFETA",
        },
        "7":{
            "id": 7,
            "name": "TAYOMI",
            "description": "All coordinators should be registered under this type",
        }
    }
}
