import React from "react"
import AddItem from "components/AddItem"
import { url, utils } from "helpers"
import { CONTENT_IDS } from "../../constants"
import moment from "moment";

export default function Member(props) {
  const nonModal = true
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff

  const isCLPDField = ({ item, values }) => {
    const member_type__name = CONTENT_IDS.member_type[`${values?.member_type}`]?.name;
    // console.log("member_type__name", member_type__name)
    return ["CLPD", "TAYOMI"].includes(member_type__name)
  }

  const columns = [
    {
      //only appear to staff
      type: "select-filter",
      id: "user",
      name: "user",
      title: "User",
      options: [],
      value: loggedUser?.id,
      canShow: item => isStaff,
      placeholder: "Choose User",
      valueField: "user.id",
      optionsURL: url.user,
      getLabel: item => `${item?.first_name} ${item?.last_name} (${item?.username})`,
      getOptionsParams: (state, obj) => {
        return ({ search: obj?.user?.id })
      },
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      required: true,
    },
    {
      type: "text",
      id: "membership_no",
      name: "membership_no",
      title: "Membership Number",
      placeholder: "Enter Membership Number",
      disabled: true,
      canShow: ({ item, values }) => !!item?.membership_no,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
    },

    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      required: true,
      id: "member_type",
      name: "member_type",
      title: "Member/Leader Type",
      optionsURL: url.membertype,
      valueField: "member_type.id",
      _setValue: item => {
        return { value: item?.member_type?.id, label: item?.member_type?.name }
      },
      options: [],
      placeholder: "Choose Member Type",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      getOptionsParams: state => ({ search: state.member_type || "" })
    },
    {
      type: "text",
      id: "phone",
      name: "phone",
      title: "Phone Number",
      placeholder: "Enter Phone Number",
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      required: true,
    },

    {
      type: "select",
      id: "memberStatus",
      name: "status",
      title: "Member Status",
      options: [
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
      placeholder: "Choose Status",
      canShow: ({ item, values }) => item?.id,
      // setValue: item => {
      //   return { value: item?.status, label: item?.status }
      // },
      formatBeforeSubmit: v => v.value,
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      required: true,
    },
    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      required: true,
      id: "region",
      name: "region",
      valueField: "region.id",
      title: "Residence Region",
      options: [],
      optionsURL: url.region,
      placeholder: "Choose Region",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: isCLPDField,
      getOptionsParams: (state, obj) => {
        return ({ search: obj?.region?.id })
      },
    },
    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      id: "branch",
      name: "branch",
      valueField: "branch.id",
      title: "Branch",
      required: true,
      optionsURL: url.branch,
      options: [],
      placeholder: "Choose Branch",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: (params) => !isCLPDField(params),
      getOptionsParams: (state, obj) => ({ member_type__id: state.member_type, search: obj?.branch?.id }),

    },
    {
      type: "date",
      id: "joined_at",
      name: "joined_at",
      title: "Joined At",
      required: true,
      placeholder: "Enter Joined Date",
      altFormat: "F j, Y",
      dateFormat: "YYYY-MM-DD",
      formatBeforeSubmit: v => moment(v).format("YYYY-MM-DD"),
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      validate: (value) => {
        let error = undefined;
        if (value) {
          if (moment(value) > moment(new Date())) {
            error = "Joining date should be a date in the past";
          }
        }
        return [value, error]
      },
    },
    {
      type: "date",
      id: "born_again_at",
      name: "born_again_at",
      title: "Born Again Date",
      placeholder: "EnterBorn Again Date",
      altFormat: "F j, Y",
      dateFormat: "YYYY-MM-DD",
      validate: (value) => {
        let error = undefined;
        if (value) {
          if (moment(value) > moment(new Date())) {
            error = "This date is not valid. Born again should be something in the past ";
          }
        }
        return [value, error]
      },
      required: true,

      formatBeforeSubmit: v => moment(v).format(),
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
    },
    {
      type: "date",
      id: "expected_graduation",
      name: "expected_graduation",
      title: "Graduated At",
      placeholder: "Enter Graduation Date",
      altFormat: "F j, Y",
      required: true,
      dateFormat: "YYYY-MM-DD",
      validate: (value) => {
        let error;
        if (value) {
          if (moment(value) > moment(new Date())) {
            error = "This graduation date is not valid. Entere a value in the past ";
          }
        }
        return [value, error]
      },
      formatBeforeSubmit: v => moment(v).format(),
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: isCLPDField,
    },
    {
      type: "date",
      required: true,
      id: "expected_graduation",
      name: "expected_graduation",
      validate: (value) => {
        let error;
        if (value) {
          if (moment(value) < moment(new Date())) {
            error = "This Expected graduation date is not valid. Entere a value in the future ";
          }
        }
        return [value, error]
      },
      title: "Expected Graduation",
      placeholder: "Enter Expected Graduation",
      altFormat: "F j, Y",
      dateFormat: "YYYY-MM-DD",
      formatBeforeSubmit: v => moment(v).format(),
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: (params) => !isCLPDField(params),
    },
    {
      type: "text",
      required: false,
      id: "program",
      name: "program",
      title: "Program",
      placeholder: "E.g Bsc. in Agriculture",
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: (p) => !isCLPDField(p),
    },

    {
      type: "select-array",
      required: true,
      id: "leaderships",
      name: "leaderships",
      valueField: "leadership.id",
      title: "Leadership Title",
      optionsURL: url.leadership,
      setValue: (item) => item?.leaderships?.map(p => ({ value: p?.id, label: p?.name })),
      formatValueBeforeSubmit: (d, item) => {
        const allIds = item?.leaderships?.map(i => i.id) || [];
        const newIds = d?.map(d => d.value) || [];
        const remove = _.difference(allIds, newIds)
        if (newIds?.length === 0 || !newIds && remove) {
          return undefined
        }
        return { "add": newIds, remove: item?.id ? remove : undefined }
      },
      // TODO: 
      getOptionsParams: (state, obj) => ({ member_type__id: state.member_type || 0, size: 200 }),
      options: [],
      placeholder: "Choose Leadership Title",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
    },
    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      required: true,
      id: "grade",
      name: "grade",
      valueField: "grade.id",
      title: "Level of Study",
      options: [],
      optionsURL: url.grade,
      placeholder: "Choose Grade",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: (p) => !isCLPDField(p),
    },
    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      required: true,
      id: "current_church",
      name: "current_church",
      valueField: "current_church.id",
      title: "Current Church",
      options: [],
      optionsURL: url.church,
      placeholder: "Choose Current Church",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
    },
    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      required: true,
      id: "home_church",
      name: "home_church",
      valueField: "home_church.id",
      title: "Home Church",
      options: [],
      optionsURL: url.church,
      placeholder: "Choose Church",
      noOptionsMessage: "Search to add new Church",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: (params) => !isCLPDField(params),
      getOptionsParams: (state, obj) => {
        return ({ search: obj?.home_church?.id })
      },
    },
    {
      type: nonModal ? "select-filter" : "select-modal-filter",
      required: true,
      id: "department",
      name: "department",
      valueField: "department.id",
      title: "Department",
      options: [],
      optionsURL: url.department,
      canShow: (p) => !isCLPDField(p),
      placeholder: "Choose Department",
      append: document.body,
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      getOptionsParams: (state, obj) => {
        return ({ search: obj?.department?.id })
      },
    },

    {
      type: "text",
      id: "career",
      name: "career",
      valueField: "career",
      title: "Career/Profession",
      placeholder: "E.g Accountant",
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
      canShow: isCLPDField,
    },
    {
      type: "text",
      id: "talent",
      name: "talent",
      valueField: "talent",
      title: "Talent",
      placeholder: "Eg. Singing",
      offset: nonModal ? 0 : 1,
      col: nonModal ? 5 : 10,
    },
  ].filter(attr => attr) //remove all undefined fields

  const config = {
    itemType: "member",
    canEdit: ({ item, values }) => !(item?.id && !isStaff),
    columns,
  }

  return <AddItem {...props} config={config} />
}
