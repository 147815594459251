import React from 'react';
import AddItem from "components/AddItem";
import { url, utils } from "helpers";
import moment from "moment";

export default function Task(props) {
    const loggedUser = utils.getUser();
    const config = {
        itemType: "todo.task",
        itemType_verbose: "Task",
        permissionsTitle: <><i className="fa fa-users"></i> Task Assignment</>,
        // attachmentQuery:{advanced_search:"tasks__id:{id}"},
        // hideGroups:true,
        columns: [
            {
                type: "text",
                id: "name",
                name: "name",
                title: "Task Name",
                placeholder: "Enter Name",
                offset: 1,
                col: 5
            },
            {
                type: "text",
                id: "description",
                name: "description",
                title: "Describe your task",
                placeholder: "Describe it",
                offset: 1,
                col: 5
            },
            {
                type: "select-filter",
                id: "task_category",
                name: "task_category",
                title: "Task Category",
                options: [],
                placeholder: "Choose Category",
                valueField: "task_category.id",
                optionsURL: url.todo.taskcategory,
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Category",
                // onCreateOption: newRegionModal, 
                append: document.body,
                offset: 1,
                col: 5
            },

            {
                type: "select-filter",
                id: "user", name: "user",
                title: "Task Created By",
                value: loggedUser?.id,
                options: [],
                // optionsURL:url.user,
                // placeholder: "Choose User", 
                valueField: "user.id",
                optionsURL: url.user,
                canShow: ({ item, values }) => loggedUser?.is_staff,
                getLabel: (v) => `${v.first_name} ${v.last_name} (${v.username})`,
                // filterOption: userFilter, noOptionsMessage: "Search to add new User", 
                // onCreateOption: newUserModal, 
                append: document.body, offset: 1, col: 5
            },
            {
                type: "calendar", id: "due_date",
                name: "due_date", title: "Due Date",
                // value: dataJson.due_date, 
                placeholder: "Enter Due Date",
                altFormat: "F j, Y",
                dateFormat: "YYYY-MM-DD", offset: 1, col: 5,
                formatValueBeforeSubmit: (d) => {
                    return moment(d).format()
                },
            },
            {
                type: "select",
                id: "taskStatus",
                name: "status",
                title: "Status",
                options: [
                    { label: "Pending", value: "pending" },
                    { label: "Completed", value: "completed" },
                    { label: "Approved", value: "approved" },
                    // { label: "Expired", value: "expired" },
                    { label: "Rejected", value: "rejected" },
                    { label: "Information", value: "information" },
                ].filter(i => i),
                getOptions:({values, options})=>{
                    return (values?.user === loggedUser?.id && !loggedUser?.is_staff)?
                    options.filter(o=>!["approved", "rejected"].includes(o.value)):options
                },
                placeholder: "Choose Status",
                append: document.body,
                offset: 1, col: 5
            },
            {
                type: "text",
                id: "comment",
                name: "comment",
                title: "Leave comment",
                placeholder: "Leave a note",
                offset: 1,
                col: 11
            },
        ]
    }

    return <AddItem {...props} config={config} />
}