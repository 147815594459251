import React from 'react';
import AddItem from "components/AddItem";
import { url } from "helpers";

export  function TransactionCategory(props) {
    const config = {
        itemType: "payments.transactioncategory",
        itemType_verbose:"Transaction Category",
        columns: [
            {
                type: "text",
                id: "name",
                name: "name",
                title: "Name",
                placeholder: "Enter Name",
                offset: 1,
                col: 5
            },
            {
                type: "text",
                id: "description",
                name: "description",
                title: "Description",
                placeholder: "Enter description",
                offset: 1,
                col: 5
            },
        ]
    }

    return <AddItem {...props} config={config} />
}