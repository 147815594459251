import React, { useState } from "react"
import moment from "moment";
import 'jspdf-autotable';
import FileSaver from 'file-saver';
import xlsx from 'xlsx';
import jsPDF from 'jspdf';
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom"
import print_icon from "assets/icons/print.png";
import invoice_icon from "assets/icons/invoice.png";
import export_icon from "assets/icons/export.png";
import downloading_icon from "assets/icons/downloading.gif";
import download_icon from "assets/icons/download.png";
import add_payments_icon from "assets/icons/add_payments.png";
import { Button } from "reactstrap";
import { ACCESS_TOKEN, PARTNER_INFO, USER_INFO } from "../constants";
import { requests, url, utils } from "helpers";

export const getTimeAmount = (date) => {
    const thisTime = moment();
    const givenTime = moment(date);
    const minuteDiff = thisTime.diff(givenTime, "minutes");
    const hourDiff = thisTime.diff(givenTime, "hours");
    const dayDiff = thisTime.diff(givenTime, "days");
    const weekDiff = thisTime.diff(givenTime, "weeks");
    const monthDiff = thisTime.diff(givenTime, "months");
    const yearDiff = thisTime.diff(givenTime, "years");

    let returnTime = "0 Minute";
    if (minuteDiff <= 59) {
        returnTime = `${minuteDiff} ${minuteDiff === 1 ? "Minute" : "Minutes"}`;
    } else if (hourDiff <= 23) {
        returnTime = `${hourDiff} ${hourDiff === 1 ? "Hour" : "Hours"}`;
    } else if (dayDiff <= 6) {
        returnTime = `${dayDiff} ${dayDiff === 1 ? "Day" : "Days"}`;
    } else if (weekDiff <= 3) {
        returnTime = `${weekDiff} ${weekDiff === 1 ? "Week" : "Weeks"}`;
    } else if (monthDiff <= 11) {
        returnTime = `${monthDiff} ${monthDiff === 1 ? "Month" : "Months"}`;
    } else if (yearDiff >= 1) {
        returnTime = `${yearDiff} ${yearDiff === 1 ? "Year" : "Years"}`;
    }
    return returnTime;
}

export const dateTimeFormat = date => {
    return date ? moment(date)?.format("YYYY-MM-DD HH:mm:ss") : "No Time"
}

export const dateFormat = (date, format = "YYYY-MM-DD") => {
    return date ? moment(date).format(format) : "No Date"
}

export const dateFormatShort = (date) => {
    return date ? moment(date)?.format("DD MMM") : "No Date"
}

export const monthName = (date) => {
    return date ? moment(date)?.format("MMMM") : "This Month"
}

export const getShortName = (string) => {
    if (string?.length > 10) {
        const limitedString = string?.substring(10)
        const indexOfLimit = 10 + limitedString?.indexOf(" ");
        return string?.substring(0, indexOfLimit) + "...";
    } else {
        return string;
    }
}

export const getShortString = (string, length) => {
    if (string?.length > length) {
        const limitedString = string?.substring(length)
        const indexOfLimit = length + limitedString?.indexOf(" ");
        return string?.substring(0, indexOfLimit) + "...";
    } else {
        return string;
    }
}

export const getShortDetails = (string) => {
    if (string?.length > 30) {
        const limitedString = string?.substring(30)
        const indexOfLimit = 30 + limitedString?.indexOf(" ");
        return string?.substring(0, indexOfLimit) + "...";
    } else {
        return string;
    }
}

export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const defaultFirstDay = () => {
    return moment('2000-01-01', 'YYYY-MM-DD')?.format("YYYY-MM-DD")
}

export const monthFirstDay = (type) => {
    return moment('2000-01-01', 'YYYY-MM-DD')?.format("YYYY-MM-DD")
}

export const monthLastDay = (type) => {
    if (type === "week") {
        moment.updateLocale('en', { week: { dow: 1, } });
    }
    return type.length > 0 ? moment()?.endOf(type)?.format("YYYY-MM-DD") : "No Date"
}

export const phoneInputFormat = (phone) => {
    return <NumberFormat thousandSeparator={false} prefix={'+'} value={phone} />
}

export const phoneNumberFormat = (phone) => {
    return <NumberFormat value={phone} displayType={'text'} thousandSeparator={false} prefix={'+'} />
}

export const toDecimal = (num, div = ",") => {
    const number = num.toString()?.replace(undefined, "")?.replace(div, "");
    const millions = getMillions(Math.round(number * 100) / 100);
    return millions.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, div);
}

export const getMillions = (num) => {
    return Math.abs(num) > 999999 ? Math.sign(num) * ((Math.abs(num) / 1000000)?.toFixed(1)) + 'M' : Math.sign(num) * Math.abs(num)
}

export const amountFormat = (amount) => {
    return <NumberFormat value={amount === null ? 0 : amount} displayType={'text'} thousandSeparator={true} prefix={JSON.parse(localStorage.getItem(PARTNER_INFO))?.currency + " "} />
}

export const decimalFormat = (amount, places = 2) => {
    return <NumberFormat value={amount === null ? 0 : amount} decimalScale={places} displayType={'text'} thousandSeparator={true} />
}

export const integerFormat = (amount, places = 2) => {
    return <NumberFormat value={amount === null ? 0 : amount} decimalScale={places} displayType={'text'} thousandSeparator={false} />
}

export const phoneFormat = () => {
    return <NumberFormat format="+1 (###) ###-####" allowEmptyFormatting mask="_" />
}

export const countFormat = (amount, places = 0) => {
    return decimalFormat(amount, places);
}

export const getUserId = () => {
    return +JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.uid;
}

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(USER_INFO));
}

export const getFullName = () => {
    const obj = JSON.parse(localStorage.getItem(USER_INFO));
    return obj?.fullName;
}

export const getUserRole = () => {
    return JSON.parse(localStorage.getItem(PARTNER_INFO))?.userRole;
}

export const getPartnerId = () => {
    return +JSON.parse(localStorage.getItem(PARTNER_INFO))?.id
}

export const getPrimaryCurrency = () => {
    return JSON.parse(localStorage.getItem(PARTNER_INFO))?.currency
}

export const getSecondaryCurrency = () => {
    return JSON.parse(localStorage.getItem(PARTNER_INFO))?.secondaryCurrency
}

export const getBase64File = file => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
}

export const dataActions = (actions) => {

    const getIcon = (icon) => {
        switch (icon) {
            case "edit_icon":
                return <i className="bx bx-pencil font-size-16 text-warning"></i>
            case "delete_icon":
                return <i className="bx bx-trash font-size-16 text-danger"></i>
            case "print_icon":
                return print_icon
            case "invoice_icon":
                return invoice_icon
            case "export_icon":
                return export_icon
            case "download_icon":
                return download_icon
            case "add_payments_icon":
                return add_payments_icon
            default:
                return ""
        }
    }

    return (
        <>
            <div className="d-inline-flex gap-3 action-center">
                {actions.length > 0 ? actions.map((item, i) => (
                    <div key={i}>
                        {!item.deletebutton && !item.passdata && item?.allowChange &&
                            <Link key={actions.id + "a" + i} to={`${item?.link || "#"}`} title={item.title}>
                                {/* <img src={getIcon(item.icon)} width="20" alt={item.title} /> */}{getIcon(item.icon)}
                            </Link>
                        }
                        {!item.deletebutton && item.passdata && item?.allowChange &&
                            <Link key={actions.id + "b" + i} to={`${item?.link || "#"}`} onClick={(e) => item?.onrowselect(e, item.data) || ""} title={item.title}>
                                {/* <img src={getIcon(item.icon)} width="20" alt={item.title} /> */}{getIcon(item.icon)}
                            </Link>
                        }
                        {item.deletebutton && item?.allowChange &&
                            <Link key={actions.id + "c" + i} to={`${item?.link || "#"}`} onClick={() => item?.onrowselect({ id: item?.id || 0, name: item?.name || "" }) || ""} title={item.title}>
                                {/* <img src={getIcon(item.icon)} width="20" alt={item.title} /> */}{getIcon(item.icon)}
                            </Link>
                        }
                    </div>
                )) : ""}
            </div>
        </>
    )
}

export const ExportItems = (props) => {
    const { requestURL} = props;
    const [loading, setLoading] = useState(false);

    const  getRowData =(d)=>props.getRowData(d, {children:true});

    const printTable = (title, dataColumns, dataList) => {
        const doc = new jsPDF(0, 0);
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const xOffset = (pageWidth / 2) - (doc.getStringUnitWidth(title) * doc.internal.getFontSize() / 5);
        doc.text(xOffset, 15, title);
        doc.autoTable(dataColumns, dataList.rows, {
            startY: 20,
            headStyles: {
                fillColor: '#dd6069',
                lineWidth: 0.5,
                lineColor: '#dd6069',
                fontSize: 8
            },
            bodyStyles: {
                lineWidth: 0.1,
                lineColor: '#dd6069',
                fontSize: 7
            },
            showHead: 'firstPage',
            autoSize: true
        });
        doc.setFont("helvetica");
        doc.setFontSize(2);
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    }

    const tableExcel = (fileName, dataColumns, dataList) => {
        const worksheet = xlsx.utils.json_to_sheet(dataList.rows, dataColumns);
        const range = xlsx.utils.decode_range(worksheet['!ref']);
        range.e['c'] = dataColumns.header.length - 1;
        worksheet['!ref'] = xlsx.utils.encode_range(range);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        xlsx.utils.book_append_sheet(workbook, worksheet, 'tab1');
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, fileName);
    }
    const saveAsExcelFile = (buffer, fileName) => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date()?.getTime() + EXCEL_EXTENSION);
    }

    async function fetchData(link, callback) {
        if(!link) return null;
        const res = await requests.get(link)
        link && callback(res)
    }


    async function printData() {
        setLoading(true);
        let results = [];
        await fetchData(requestURL, async(res)=>{
            results = [...results, ...res.results]
           await fetchData(res.next, fetchData)
        })
        const rows = results.map(getRowData)
        printTable(props.title, props.exportColumns, { rows })
        setLoading(false)
    }

    async function toExcel() {
        setLoading(true)
        let results = [];
        await fetchData(requestURL, async(res)=>{
            results = [...results, ...res.results]
           await fetchData(res.next, fetchData)
        })
        const rows = results.map(getRowData)
        tableExcel(props.title, props.excelCols, { rows })
        setLoading(false)
    }


    return (
        <> {true && 
            <Button disabled={loading} type="button" onClick={toExcel} title="Export Excel" color="default" className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1">
            {<img src={!loading?invoice_icon:downloading_icon} width={loading?"120":"20"} height={loading?"80":"20"} alt={props.title} />}
            </Button>}
            {true && <Button disabled={loading} type="button" onClick={printData} title="Print Pdf" color="default" className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1">
                {!loading && <img src={print_icon} width="20" height="20" alt={props.title} />}
            </Button>}
            
        </>
    )
}