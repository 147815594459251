import React, { useState, useEffect, useCallback, useMemo } from "react"
import { Col, Row, Label, InputGroup } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
import PasswordStrengthBar from "react-password-strength-bar"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import Creatable, { makeCreatableSelect } from "react-select/creatable"
import { InfiniteLoader, List, AutoSizer } from "react-virtualized"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  dateFormat,
  dateTimeFormat,
  getBase64File,
} from "../utilities/TayomiUtil"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import _ from "lodash"
import { requests, utils } from "helpers"
import moment from "moment";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export function FormField(props) {
  const { dataJson = {}, index } = props
  const [item, setItem] = useState(props.item);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(item.options || [])
  const [inputValue, setInputValue] = useState()
  const [searchInput, setSearchInput] = useState("")
  const [optionsURL, setOptionsURL] = useState(item.optionsURL);
  const [errorMessage, setErrorMessage] = useState(item.errorMessage);
  const [obj, setObj] = useState(props.obj)
  const [optionsParams, setOptionsParams] = useState(item.getOptionsParams && item.getOptionsParams(dataJson, obj))
  const pageSize = 100; //item.pageSize
  const maxPage = 10
  const rowCount = pageSize * maxPage
  const rowHeight = 30
  const maxListheight = pageSize * rowHeight
  const currentListHeight = pageSize * rowHeight
  const listHeight = options?.length > currentListHeight ? maxListheight : currentListHeight

  const loadMoreRows = ({ startIndex }) => {
    const newPage = Math.floor(startIndex / pageSize)
    return item.setNextIndex(newPage)
  }

  useEffect(()=>{
    setObj(props.obj)
  }, [props.obj])

  useEffect(() => {
    setItem(props.item)
  }, [props.item, index])

  useEffect(() => {
    let params = item.getOptionsParams && item.getOptionsParams(dataJson, obj)
    if(!params && obj){
      const _search = utils.getObject(obj, item.valueField||item.name)
      params = _search && {
        search: _search
      }
    }
    if (!_.isEqual(params, optionsParams)) {
      params && setOptionsParams(params)
    }
  }, [dataJson, index, obj])


  useEffect(() => {
    const o = item.getOptions ? item.getOptions({
            values: dataJson, options: item.options,
          }) : item.options
    
    !_.isEqual(o, options) && setOptions(o)
    optionsURL!==item.optionsURL && setOptionsURL(item.optionsURL)
  }, [item.optionsURL, index])

  useEffect(() => {
    const v = dataJson[item.name]||optionsParams?.search
    if(v!==inputValue){
      setInputValue(v)
    }
  }, [dataJson[item.name], optionsParams, index])

  const getData = useCallback(
    _.debounce((link, params) => {
      link && fetchOptions(link, params)
    }, 1000),
    []
  )

  useEffect(() => {
      optionsURL &&  getData(optionsURL, { 
        ...optionsParams, 
        search: `${searchInput||optionsParams?.search||""}`, 
        size: optionsParams?.size||51,
      })
  }, [searchInput, optionsParams, index])

  function filterOption(option, search) {
    if (item.filterOption) {
      return item.filterOption(option, search)
    } else {
      setSearchInput(search)
      return option.label?.toLowerCase().includes(search?.toLowerCase())
    }
  }

  const fetchOptions = async(link, extraParams = {})=> {
    try {
      setLoading(true);
      const urlParams = {...optionsParams,  ...extraParams, }
      const res = await requests.get(link, {...urlParams})
      const _options = res.results.map(option => ({
        value: item.getValue ? item.getValue(option) : option.id,
        label: item.getLabel
          ? item.getLabel(option)
          : option.name || option.title || option.description,
      }))
      setOptions(_options)
    } catch (error) {
      if (item.onError) {
        item.onError(error)
      } else {
        // alert("Error fetching options: " + error.message+JSON.stringify(error.data))
        console.log("Error fetching options: " + error.message + JSON.stringify(error.data));
      }
    }
    setLoading(false);
  }


  const MenuList = ({ children }) => {
    const childrenArray = React.Children.toArray(children)
    const rowRenderer = ({ index, isScrolling, isVisible, style, ...rest }) => {
      const child = childrenArray[index]
      return (
        <div
          style={{
            borderBottom: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
            ...style,
          }}
          {...rest}
        >
          {child ? child : `${index}. Loading...`}
        </div>
      )
    }
   
    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => !!options[index]}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
        threshhold={5}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={listHeight}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                rowCount={rowCount}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }

  switch (item.type) {
    case "text":
      return (
        <AvField
          {...item}
          type="text"
          id={item.id}
          name={item.name}
          value={inputValue}
          onChange={e =>
            props.setDataJson({
              ...props.dataJson,
              [item.name]: e.target.value,
            })
          }
          label={item.title}
          className="form-control"
          placeholder={item.placeholder}
          required={item?.required || false}
          disabled={item?.disabled || false}
          readOnly={item?.readOnly || false}
          errorMessage={errorMessage}
        />
      )
    case "phone":
      return (
        <PhoneInput
          id={item.id}
          name={item.name}
          defaultCountry="US"
          value={inputValue}
          onChange={value =>
            props.setDataJson({ ...props.dataJson, [item.name]: value })
          }
          label={item.title}
          className="form-control d-flex"
          placeholder={item.placeholder}
          required={item?.required || false}
          disabled={item?.disabled || false}
          readOnly={item?.readOnly || false}
          errorMessage={errorMessage}
        />
      )
    case "password":
      return (
        <>
          <AvField
            type="password"
            id={item.id}
            name={item.name}
            value={inputValue}
            onChange={e =>
              props.setDataJson({
                ...props.dataJson,
                [item.name]: e.target.value,
              })
            }
            label={item.title}
            className="form-control"
            placeholder={item.placeholder}
            required={item?.required || false}
            disabled={item?.disabled || false}
            readOnly={item?.readOnly || false}
            errorMessage={errorMessage}
          />
          <strong>
            <PasswordStrengthBar
              password={inputValue}
              scoreWordStyle={{ fontSize: "10px" }}
            />
          </strong>
        </>
      )
    case "number":
      return (
        <AvField
          type="number"
          defaultValue="0"
          id={item.id}
          name={item.name}
          value={inputValue}
          onChange={e =>
            props.setDataJson({
              ...props.dataJson,
              [item.name]: e.target.value,
            })
          }
          label={item.title}
          className="form-control"
          placeholder={item.placeholder}
          errorMessage={errorMessage}
          min={item?.min || 1}
          max={item?.max || 999999}
          required={item?.required || false}
          disabled={item?.disabled || false}
          readOnly={item?.readOnly || false}
        />
      )
    case "decimal":
      return (
        <AvField
          type="text"
          id={item.id}
          name={item.name}
          value={inputValue}
          value={utils.formatNumber(inputValue || 0)}
          onChange={e => {
            const value = utils.extractDigits(e.target.value)?.toString();
            props.setDataJson({
              ...props.dataJson,
              [item.name]: value
            })
          }}
          label={item.title}
          className="form-control"
          errorMessage={errorMessage}
          placeholder={item.placeholder}
          required={item?.required || false}
          disabled={item?.disabled || false}
          readOnly={item?.readOnly || false}
        />
      )
    case "date":
      return (<>
        <AvField
          type="date"
          id={item.id}
          name={item.name}
          invalid={!!errorMessage}
          value={inputValue ? moment(inputValue).format(item.dateFormat || "YYYY-MM-DD") : inputValue}
          onChange={e => {
            let value = e.target.value
            if (item.validate) {
              const [_value, error] = item.validate(value)
              if (!error) {
                setErrorMessage(undefined);
                const v = (_value ? moment(_value).format(item.dateFormat || "YYYY-MM-DD") : _value);
                return props.setDataJson({
                  ...props.dataJson,
                  [item.name]: v,
                })
              } else {
                setErrorMessage(error);
                setInputValue(undefined);
                // alert(error)
                return;
              }
            } else {
              setInputValue(value)
              return props.setDataJson({
                ...props.dataJson,
                [item.name]: value,
              })
            }
          }
          }
          options={{
            altInput: true,
            altFormat: item.altFormat || "F j, Y",
            dateFormat: item.dateFormat || "YYYY-MM-DD HH:mm:ss",
          }}

          // validate={{dateRange: {format: 'MM/DD/YYYY', start: {value: '01/01/2010'}, end: {value: '12/31/2020'}}}}
          label={item.title}
          className="form-control"
          placeholder={item.placeholder}
          errorMessage={errorMessage}
          required={item?.required || false}
          disabled={item?.disabled || false}
          readOnly={item?.readOnly || false}
        />
        <div className="font-size-13 text-primary pt-2">
          {errorMessage}
        </div>
      </>
      )
    case "datetime":
      return (
        <div className="form-group mb-4">
          <Label>{item.title}</Label>
          <InputGroup>
            <Flatpickr
              id={item.id}
              value={new Date(Date.parse(inputValue))}
              name={item.name}
              onChange={e =>
                props.setDataJson({
                  ...props.dataJson,
                  [item.name]: dateTimeFormat(new Date(Date.parse(e))),
                })
              }
              className="form-control d-block"
              placeholder={item.placeholder}
              errorMessage={errorMessage}
              options={{
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "YYYY-MM-DD HH:mm:ss",
              }}
              required={item?.required || false}
              disabled={item?.disabled || false}
              readOnly={item?.readOnly || false}

            />
          </InputGroup>
        </div>
      )
    case "calendar":
      return (
        <div className="form-group mb-4">
          <Label>{item.title}</Label>
          <InputGroup>
            <Flatpickr
              id={item.id}
              value={new Date(Date.parse(inputValue))}
              name={item.name}
              onChange={e =>
                props.setDataJson({
                  ...props.dataJson,
                  [item.name]: dateFormat(new Date(Date.parse(e))),
                })
              }
              className="form-control d-block"
              placeholder={item.placeholder}
              errorMessage={errorMessage}
              options={{
                altInput: true,
                altFormat: item.altFormat,
                dateFormat: item.dateFormat,
                clickOpens: !(item?.readOnly || item?.disabled || false)
              }}
            />
          </InputGroup>
        </div>
      )
    case "select":
      return (
        <div>
          <Label>{item.title}</Label>
          <Select
            inputId={item.id}
            load={loading} isLoading={loading}
            // value={inputValue}
            value={
              !item.isMulti
                ? options?.filter(option => option.value === inputValue)
                : inputValue
            }
            isMulti={item?.isMulti || false}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e.value })
            }
            options={options}
            menuPortalTarget={document.querySelector("body")}
            errorMessage={errorMessage}
            classNamePrefix="select2-selection"
            isLoading={item?.isLoading || false}
            appendTo={document.body}
            isDisabled={item?.readOnly || item?.disabled || false}
            required
          />
        </div>
      )
    case "select-array":
      return (
        <div>
          <Label>{item.title}</Label>
          <Select
            inputId={item.id}
            value={inputValue}
            isMulti={true}
            isClearable={true}
            load={loading} isLoading={loading}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e })
            }
            options={options}
            menuPortalTarget={document.querySelector("body")}
            classNamePrefix="select2-selection"
            appendTo={document.body}
            isDisabled={item?.readOnly || item?.disabled || false}
            errorMessage={errorMessage}
            filterOption={filterOption}
            required={item?.required || false}
            placeholder={item.placeholder}
            isLoading={item?.isLoading || false}

          />
        </div>
      )
    case "select-modal":
      return (
        <div>
          <Label>{item.title}</Label>
          <Select
            inputId={item.id}
            value={
              !item.isMulti
                ? options.filter(option => option.value === inputValue)
                : inputValue
            }
            isMulti={item?.isMulti || false}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e.value })
            }
            load={loading} isLoading={loading}
            options={options}
            classNamePrefix="select2-selection"
            isLoading={item?.isLoading || false}
            errorMessage={errorMessage}
            appendTo={document.body}
            isDisabled={item?.readOnly || item?.disabled || false}
            required
          />
        </div>
      )
    case "select-filter":
      return (
        <div>
          <Label>{item.title}</Label>
          <Creatable
            inputId={item.id}
            value={
              !item.isMulti
                ? options?.filter(option => option.value === inputValue)
                : inputValue
            }
            isMulti={item?.isMulti || false}
            onChange={e =>
              props.setDataJson({
                ...props.dataJson,
                [item.name]: e !== null ? e.value : "",
              })
            }
            load={loading} isLoading={loading}
            options={options}
            filterOption={filterOption}
            getOptionLabel={option => option.label}
            errorMessage={errorMessage}
            getOptionValue={option => option.value}
            noOptionsMessage={() => item.noOptionsMessage}
            onCreateOption={item.onCreateOption}
            styles={{
              menu: styles => ({
                ...styles,
                position: "absolute",
                zIndex: 1000,
              }),
            }}
            menuPortalTarget={document.querySelector("body")}
            classNamePrefix="select2-selection"
            isLoading={item?.isLoading || false}
            isClearable={true}
            appendTo={document.body}
            placeholder={item.placeholder}
            required={item?.required || false}
            isDisabled={item?.readOnly || item?.disabled || false}
          />
        </div>
      )
    case "select-array-filter":
      return (
        <div>
          <Label>{item.title}</Label>
          <Creatable
            load={loading} isLoading={loading}
            inputId={item.id}
            value={inputValue}
            isMulti={true}
            isClearable={true}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e })
            }
            options={options}
            classNamePrefix="select2-selection"
            filterOption={item.filterOption}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            noOptionsMessage={() => item.noOptionsMessage}
            onCreateOption={item.onCreateOption}
            styles={{
              menu: styles => ({
                ...styles,
                position: "absolute",
                zIndex: 1000,
              }),
            }}
            menuPortalTarget={document.querySelector("body")}
            appendTo={document.body}
          />
        </div>
      )
    case "select-modal-filter":
      return (
        <div>
          <Label>{item.title}</Label>
          <Creatable
            inputId={item.id}
            value={
              !item.isMulti
                ? options.filter(option => option.value === inputValue)
                : inputValue
            }
            isMulti={item?.isMulti || false}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e.value })
            }
            load={loading} isLoading={loading}
            options={options}
            filterOption={item.filterOption}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            noOptionsMessage={() => item.noOptionsMessage}
            onCreateOption={item.onCreateOption}
            classNamePrefix="select2-selection"
            isLoading={item?.isLoading || false}
            appendTo={document.body}
            required
          />
        </div>
      )
    case "select-modal-array-filter":
      return (
        <React.Fragment>
          <Label>{item.title}</Label>
          <Creatable
            load={loading} isLoading={loading}
            inputId={item.id}
            value={inputValue}
            isMulti={true}
            isClearable={true}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e })
            }
            // style={{overflow:"auto"}}
            options={options}
            classNamePrefix="select2-selection"
            filterOption={item.filterOption}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            noOptionsMessage={() => item.noOptionsMessage}
            onCreateOption={item.onCreateOption}
            appendTo={document.body}
          />
        </React.Fragment>
      )
    case "select2":
      return (
        <div>
          <Label>{item.title}</Label>
          <Select
            inputId={item.id}
            value={
              !item.isMulti
                ? options.filter(option => option.value === inputValue)
                : inputValue
            }
            isMulti={item?.isMulti || false}
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: e.value })
            }
            options={options}
            styles={{
              menu: styles => ({
                ...styles,
                position: "absolute",
                zIndex: 1000,
              }),
            }}
            menuPortalTarget={document.querySelector("body")}
            classNamePrefix="select2-selection"
            load={loading} isLoading={loading}
            defaultMenuIsOpen={false}
            components={{ MenuList }}
            appendTo={document.body}
            required
          />
        </div>
      )
    case "file":
      return (
        <div>
          <Label>{item.title}</Label>
          <br />
          <input
            type="file"
            name={item.name}
            onChange={e => handleFile(e.target.files[0], item.name)}
            required
          />
        </div>
      )
    case "boolean":
      return (
        <div className="mt-3">
          <Label className="mr-4" style={{ marginRight: "2rem" }}>
            {item.title}:
          </Label>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            name={item.name}
            value={inputValue}
            checked={inputValue}
            disabled={item?.readOnly || item?.disabled || false}
            onColor="#8981bd"
            onChange={e =>
              props.setDataJson({ ...props.dataJson, [item.name]: !inputValue })
            }
          />
        </div>
      )
    case "radio":
      return (
        <div>
          <Label>{item.title}</Label>
          {item.radios.map((radio, i) => (
            <Col
              xs={`${12 / radio.count}`}
              sm={`${12 / radio.count}`}
              md={`${12 / radio.count}`}
              lg={`${12 / radio.count}`}
            >
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name={radio.name}
                  id={radio.id}
                  value={radio.value}
                  disabled={item?.readOnly || item?.disabled || false}
                  checked={inputValue === radio.value}
                  onChange={e =>
                    props.setDataJson({
                      ...props.dataJson,
                      [item.name]: e.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor={radio.name}>
                  {radio.valueTitle}
                </label>
              </div>
            </Col>
          ))}
        </div>
      )
    default:
      return ""
  }
}

const FormAttributes = props => {
  const [attributes, setAttributes] = useState(props.attributes)
  const [obj, setObj] = useState(props.obj)

  useEffect(()=>{
    setObj(props.obj)
  }, [props.obj])
  useEffect(() => {
    setAttributes(props.attributes)
  }, [props.attributes])


  const handleFile = (file, name) => {
    getBase64File(file).then(result => {
      file["base64"] = result
      props.setDataJson({
        ...props.dataJson,
        [name]: result.replace("data:image/png;base64,", ""),
      })
    })
  }

  // console.log("obj FormAttributes", obj?.id)

  return (
    <Row>
      {attributes.length > 0
        ? attributes.map((item, index) => (
          <Col
            xs={12}
            sm={12}
            md={item.col}
            lg={item.col}
            xl={item.col}
            className={`offset-md-${item.offset} offset-lg-${item.offset} offset-xl-${item.offset}`}
            key={`${item.name}_${index}`}
          >
            <div className={item.type === "password" ? "mb-0" : "mb-2"}>
              <FormField
                // attributes={attributes}
                index={`${item.name}_${index}_${obj?.id}`}
                item={item}
                obj={obj}
                setDataJson={props.setDataJson}
                dataJson={props.dataJson}
              />
            </div>
          </Col>
        ))
        : ""}
    </Row>
  )
}

export default FormAttributes
