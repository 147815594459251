import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Alert,
    Button,
    Spinner,
    Modal, ModalHeader,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { dataActions, monthFirstDay, monthLastDay } from "utilities/TayomiUtil"
import { PageableTable } from "components/Common/PageableTable"
import { DeleteItem } from "utilities/DeleteItem"
import { requests, url, utils } from "helpers"
import AddItem from "components/AddItem";

export default function Table(props) {
    const history = useHistory()
    const {
        columns = [],
        itemType = "",
        itemType_verbose = "",
        title = "List of items",
        subtitle = "",
        advancedSearch,
        summaryColumns = [{ title: "Total", field: "count" }]
    } = props

    const {addItemLink=`/${itemType.replace(".", "/")}/0`}=props;
    const [dataList, setDataList] = useState({})
    const [filter, setFilter] = useState(props.filter || {})
    const [loading, setLoading] = useState(true)
    const [first, setFirst] = useState(0)
    const [meta, setMeta] = useState({})
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [sortOrder, setSortOrder] = useState(-1)
    const [sortField, setSortField] = useState("id")
    const [alert, setAlert] = useState("")
    const [failed, setFailed] = useState(null)
    const [canAdd, setCanAdd] = useState(false)
    const [dataId, setDataId] = useState(0)
    const [displayConfirmation, setDisplayConfirmation] = useState(false)
    const [deleteData, setDeleteData] = useState(0)
    const [isPaginated, setIsPaginated] = useState(false);
    const [displayAdvancedFilter, setDisplayAdvancedFilter] = useState(false);
    const [paramsJson, setParamsJson] = useState({
        ...filter,
        search: filter.globalFilter,
        created__gte: filter.createdGte,
        created__lte: filter.createdLte,
        index: pageIndex,
        page: pageIndex,
        size: pageSize,
        order_by: "-id",
    })

    const pdfCols = columns.map(col => ({ field: col.field, header: col.label })).filter(col => col.field !== "action")
    const excelCols = { header: [] }
    const exportColumns = pdfCols.map(col => ({
        title: col.header,
        dataKey: col.field,
    }))

    const startURL = url.getURL(itemType, { type: "list" });

    const getRowData = (d, {children=false}={}) => {
        let obj = {}
        let canEdit = false
        let canDelete = false
        columns.map(col => {
            let v = col.field === "."?d:utils.getObject(d, col.field)
            if (col.formatValue) v = children?(col.formatValue(v, d)?.props?.children||col.formatValue(v, d)):col.formatValue(v, d)
            obj[col.field] = v
            if (col.canEdit) {
                canEdit = true
            }
            if (col.canDelete) {
                canDelete = true
            }
        })

        return {
            ...obj,
            action: dataActions([
                {
                    id: d.id,
                    bg: "btn-warning",
                    icon: "edit_icon",
                    link: `/${itemType.replace(".", "/")}/${d.id}`,
                    title: `Edit ${itemType}`,
                    allowChange:
                        canEdit ||
                        d.permissions?.includes(
                            `change_${itemType.split(".").slice(-1)[0]}`
                        ),
                },
                {
                    id: d.id,
                    bg: "btn-danger",
                    icon: "delete_icon",
                    name: d.name,
                    deletebutton: true,
                    onrowselect: onDeleteButtonClick,
                    title: `Delete ${itemType}`,
                    allowChange:
                        (d._permissions || d.permissions)?.includes(
                            `delete_${itemType.split(".").slice(-1)[0]}`
                        ),
                },
            ]),
        }
    }

    const fetchData = (extra={}) => {
        requests
            .get(startURL, { ...filter, ...paramsJson, ...extra||{} })
            .then(data => {
                const dataContent = data.results.map(getRowData)
                setDataList({ columns, rows: dataContent })
                setLoading(false)
                setTotalPages(data.total_pages);
                setTotalItems(data.count)
                setIsPaginated(data.is_paginated);
                setCanAdd(data.permissions?.can_add);
                setMeta(data);
            })
            .catch(error => {
                setDataList([])
                setLoading(false)
            }).finally(()=>{
                // TODO: replace, push url to window history
            })
    }

    useEffect(() => {
        fetchData()
    }, [filter, sortField, sortOrder, first, pageSize, paramsJson])

    const handlePageClick = page => {
        setPageIndex(page);
        setParamsJson({
            ...paramsJson, page
        })
    }

    const onSortChange = e => {
        setSortOrder(e.direction)
        setSortField(e.column)
    }

    const onDeleteButtonClick = data => {
        setDataId(data.id)
        setDeleteData(data.name || data.title || data.description || data.username)
        setDisplayConfirmation(true)
    }

    const removeData = () => {
        setDisplayConfirmation(false)
        requests
            .delete(url.getURL(itemType, { type: "delete", item: { id: dataId } }))
            .then(response => {
                setDataId(0)
                setFailed(false)
                setAlert("Item removed successfully!")
                fetchData()
            })
            .catch(error => {
                setFailed(true)
                setAlert(error?.message || "Failed to remove item")
                fetchData()
            })
    }

    const addItemClick = () => {
        history.push(addItemLink)
    }

    function toggleAdvancedFilter() {
        // alert("Toggled")
        setDisplayAdvancedFilter(!displayAdvancedFilter)
    }

    // console.log("isPaginated1", isPaginated)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{itemType_verbose}</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title={"home"} breadcrumbItem={`${itemType_verbose}(s)`} />
                    <Row>
                        <Col className="col-12">
                            {failed === false && <Alert color="success">{alert}</Alert>}
                            {failed === true && <Alert color="danger">{alert}</Alert>}
                            {loading && (
                                <div className="col-4 offset-4 mb-3 text-center">
                                    <Spinner className="ms-2" color="primary" />
                                </div>
                            )}
                            {dataList?.rows?.length <= 0 &&
                                filter?.globalFilter?.length <= 0 &&
                                filter?.createdGte === monthFirstDay("month") &&
                                filter?.createdLte === monthLastDay("month") && (
                                    <div>
                                        <CardTitle className="mb-2 text-center">
                                            No {itemType_verbose}s!
                                        </CardTitle>
                                        {canAdd && (
                                            <CardSubtitle className="mb-3 mt-2 text-center">
                                                Add {itemType_verbose} now!
                                                <br />
                                                <Button
                                                    type="button"
                                                    onClick={() => addItemClick()}
                                                    color="primary"
                                                    className="btn btn-sm btn-rounded waves-effect waves-light mt-2 me-0"
                                                >
                                                    <i className="bx bx-plus" /> Add {itemType_verbose}
                                                </Button>
                                            </CardSubtitle>
                                        )}
                                    </div>
                                )}
                            {(dataList?.rows?.length > 0 ||
                                (dataList?.rows?.length <= 0 &&
                                    filter?.globalFilter?.length > 0) ||
                                filter?.createdGte !== monthFirstDay("month") ||
                                filter?.createdLte !== monthLastDay("month")) && (
                                    <Card>
                                        <CardBody>
                                            <div className="border p-2 mb-2">
                                                <Row className="row d-flex">
                                                    <CardTitle>{title}</CardTitle>
                                                    {!!meta && summaryColumns.map((col, i) => (
                                                        <Col sm="4" md={"6"} key={`col-${i}`}>
                                                            <p><strong>{col.title}</strong>: {meta[col.field]}</p>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <CardSubtitle className="mb-0">{subtitle}</CardSubtitle>
                                            </div>
                                            {displayAdvancedFilter && <AddItem
                                                {...props}
                                                dataJson={paramsJson}
                                                onSubmit={(dataJson) => {
                                                    toggleAdvancedFilter();
                                                    // setParamsJson({ ...paramsJson, ...dataJson });
                                                    // console.log("dataJson", dataJson)
                                                }}
                                                match={{ params: { displayElement: false, nonModal: false } }}
                                                onDataJsonChange={(dataJson) => setParamsJson({ ...paramsJson, ...dataJson })} config={advancedSearch}
                                            />}
                                            <PageableTable
                                                dataList={dataList}
                                                pageIndex={pageIndex}
                                                totalPages={totalPages}
                                                totalItems={totalItems}
                                                pageSize={pageSize}
                                                handlePageClick={handlePageClick}
                                                filter={filter}
                                                requestURL={utils.stringify({ ...filter, ...paramsJson, size:400 }, {
                                                    baseURL: startURL
                                                })}
                                                getRowData={getRowData}
                                                setFilter={(data) => {
                                                    if (data.resetAll) {
                                                        setParamsJson({})
                                                        toggleAdvancedFilter && toggleAdvancedFilter()
                                                    } else {
                                                        setParamsJson({ ...paramsJson, ...data, page:undefined })
                                                    }
                                                }}
                                                exportToCSV={false}
                                                addItem={canAdd && `Add ${itemType_verbose || itemType}`}
                                                addItemLink={canAdd && addItemLink}
                                                excelCols={excelCols}
                                                exportColumns={exportColumns}
                                                title={itemType_verbose || itemType}
                                                isPaginated={isPaginated}
                                                toggleAdvancedFilter={advancedSearch ? toggleAdvancedFilter : undefined}
                                            />
                                        </CardBody>
                                    </Card>
                                )}
                        </Col>
                    </Row>
                </div>
            </div>
            <DeleteItem
                displayConfirmation={displayConfirmation}
                setDisplayConfirmation={setDisplayConfirmation}
                deleteData={deleteData}
                removeData={removeData}
            />
            {!!advancedSearch && false &&
                <Modal
                    isOpen={false && displayAdvancedFilter}
                    // role="dialog"
                    autoFocus={true}
                    centered={true}
                    size={"xl"}
                    tabIndex="100"
                    data-toggle="modal"
                    fullscreen={true}
                    scrollable={true}
                    toggle={toggleAdvancedFilter}
                    className="container-fluid"

                >
                    <div className="modal-content container-fluid"
                        style={{ height: "100vh" }}>
                        <div className="modal-header border-bottom-0">
                            <ModalHeader
                                toggle={toggleAdvancedFilter}
                            >
                                Advancedd Filters
                            </ModalHeader>
                        </div>
                        <AddItem
                            {...props}
                            dataJson={paramsJson}
                            onSubmit={(dataJson) => {
                                toggleAdvancedFilter();
                                setParamsJson({ ...paramsJson, ...dataJson });
                                console.log("dataJson", dataJson)
                            }}
                            match={{ params: { displayElement: false, nonModal: false } }}
                            onDataJsonChange={(dataJson) => setParamsJson({ ...paramsJson, ...dataJson })} config={advancedSearch}
                        />
                    </div>
                </Modal>}

        </React.Fragment>
    )
}
