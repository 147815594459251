import React from 'react';
import AddItem from "components/AddItem";
import { url, utils } from "helpers";

export function Fee(props) {
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff;
  const nonModal = true;
  const displayElement = true;

  const config = {
    itemType: "payments.fee",
    itemType_verbose: "fee",
    permissionsTitle: "Share Access",
    // attachmentQuery:{advanced_search:"fees__id:{id}"},
    columns: [
      {
        type: "text",
        id: "name",
        name: "name",
        title: "Fee Name",
        // value: dataJson.amount,
        placeholder: "Enter Name",
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: "decimal",
        id: "amount",
        name: "amount",
        title: "Amount",
        // value: dataJson.amount,
        placeholder: "Enter Amount",
        offset: 1,
        col: nonModal ? 5 : 10,
      },

      {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "member_type",
        name: "member_type",
        title: "Member Type",
        options: [],
        optionsURL: url.membertype,
        valueField: "member_type.id",
        placeholder: "Choose Member Type",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "branch_type",
        name: "branch_type",
        title: "Branch Type",
        options: [],
        optionsURL: url.branchtype,
        valueField: "branch_type.id",
        placeholder: "Choose Branch Type",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: "select-array",
        required: true,
        id: "source_accounts",
        name: "source_accounts",
        valueField: "source_accounts",
        title: "Accounts to be Charged",
        optionsURL: url.payments.account,
        setValue: (item) => item?.source_accounts?.map(p => ({ value: p?.id, label: p?.name })),
        formatValueBeforeSubmit: (d, item) => {
          const allIds = item?.source_accounts?.map(i => i.id) || [];
          const newIds = d?.map(d => d.value) || [];
          const remove = _.difference(allIds, newIds)
          if (newIds?.length === 0 || !newIds && remove) {
            return undefined
          }
          return { "add": newIds, remove: item?.id ? remove : undefined }
        },
        // getOptionsParams: state => ({ member_type__id: state.member_type || 0 }),
        options: [],
        placeholder: "Choose Accounts",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
         {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "account",
        name: "account",
        title: "Collection Account",
        // value: dataJson.from_account,
        options: [],
        optionsURL: url.payments.account,
        valueField: "account.id",
        placeholder: "Choose Account",
        noOptionsMessage: "Search to add new Account",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: displayElement ? "select-filter" : "select-modal-filter",
        id: "transaction_category",
        name: "transaction_category",
        title: "Transaction Category",
        // value: dataJson.from_account,
        options: [],
        optionsURL: url.payments.transactioncategory,
        valueField: "transaction_category.id",
        placeholder: "Choose Category",
        noOptionsMessage: "Search to add new",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
      {
        type: "select-array",
        required: true,
        id: "groups",
        name: "groups",
        valueField: "groups",
        title: "Member Groups to be Charged",
        optionsURL: url.group,
        setValue: (item) => item?.groups?.map(p => ({ value: p?.id, label: p?.name })),
        formatValueBeforeSubmit: (d, item) => {
          const allIds = item?.groups?.map(i => i.id) || [];
          const newIds = d?.map(d => d.value) || [];
          const remove = _.difference(allIds, newIds)
          if (newIds?.length === 0 || !newIds && remove) {
            return undefined
          }
          return { "add": newIds, remove: item?.id ? remove : undefined }
        },
        // getOptionsParams: state => ({ member_type__id: state.member_type || 0 }),
        options: [],
        placeholder: "Choose Groups",
        append: document.body,
        offset: 1,
        col: nonModal ? 5 : 10,
      },
    ].filter(v => v)
  }
  return <AddItem {...props} config={config} />
}


