import React from "react"
import AddItem from "components/AddItem"
import { url, utils } from "helpers"

export default function Account(props) {
  const loggedUser = utils.getUser()
  const isStaff = loggedUser?.is_staff;
  const displayElement = true;
  const nonModal = true;

  const columns = [
    {
      type: "text",
      id: "name",
      name: "name",
      title: "Account",
      placeholder: "Enter Account",
      offset: displayElement ? 0 : 1,
      col: displayElement ? 5 : 10,
    },
   /* {
      type: "decimal",
      id: "balance",
      name: "balance",
      title: "Balance",
      placeholder: "Enter Balance",
      offset: displayElement ? 0 : 1,
      col: displayElement ? 5 : 10,
    },*/

    { //only appear to staff
        type: "select-filter",
        id: "user", 
        name: "user", 
        title: "Account Owner", 
        options: [], 
        value:loggedUser?.id,
        canShow:({item, values})=>false,
        placeholder: "Choose Account Owner", 
        valueField:"user.id",
        optionsURL:url.user,
        getLabel:(item)=>`${item?.first_name} ${item?.last_name} (${item?.username})`,
        // filterOption: regionFilter, 
        noOptionsMessage: "Search User", 
        // onCreateOption: newRegionModal, 
        append: document.body, 
        offset: displayElement ? 0 : 1,
        col: displayElement ? 5 : 10,
    },
    {
      type: "text",
      id: "description",
      name: "description",
      title: "Description",
      placeholder: "Enter Description",
      offset: displayElement ? 0 : 1,
      col: displayElement ? 5 : 10,
    },
    // {
    //   type: "boolean",
    //   id: "is_active",
    //   name: "is_active",
    //   title: "Is Active",
    //   placeholder: "Enter Is Active",
    //   offset: displayElement ? 0 : 1,
    //   col: displayElement ? 5 : 10,
    // },
  ]

  const config = {
    userNotRequired:true,
    itemType: "payments.account",
    itemType_verbose:"Account",
    permissionsTitle:"Manage Account Access",
    canEdit: ({item, values}) => !(item?.id && !isStaff),
    columns,
  }

  return <AddItem {...props} config={config} />
}
