import React from "react"
import AddItem from "components/AddItem"
import { url } from "helpers"

export default function Church(props) {
  const config = {
    itemType: "church",
    columns: [
      {
        type: "text",
        id: "name",
        name: "name",
        title: "Church Name",
        placeholder: "Enter Name",
        offset: 1,
        col: 5,
      },
      {
        type: "select-filter",
        id: "denomination",
        name: "denomination",
        title: "Denomination",
        valueField: "denomination.id",
        optionsURL: url.denomination,
        formatBeforeSubmit: v => v.value,
        options: [],
        placeholder: "Choose Denomination",
        noOptionsMessage: "Search to add new denomination",
        append: document.body,
        offset: 0,
        col: 5,
      },
    ],
  }

  return <AddItem {...props} config={config} />
}
