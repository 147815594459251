import { CORE_MODULE } from "../../constants";
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { requests, url, utils } from "helpers";

const StackedColumnChart = (props) => {
    const [series, setSeries] = useState([
      { name: "O-Level", data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48] },
      { name: "A-Level", data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22] },
      // { name: "College", data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18] },
      // { name: "University", data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18] },
      // { name: "Others", data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18] }
    ]);
    const [loading, setLoading] = useState(false);
    const [graphType, setGraphType] = useState(props.graph)
    const fetchData = async() => {
      try{
        const res = await requests.get(`${url.member}graph/`)
        let chartData = [];
        for ( let property in res ) {
            chartData.push({name: property, data: res[[property]]})
        }
        setSeries(chartData);
      }catch(error){
      }
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
      async function updateGraphType(){
        setGraphType(props.graph);
        setLoading(true);
        await utils.sleep(1)
        setLoading(false);

      }
      updateGraphType();
    }, [props.graph])


    const options = {
      chart: {
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%"
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        show: true,
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        labels: {
          show: true
        }
      },
      colors: ["#dd6069", "#f1b44c", "#34c38f", "#dd6069", "#8981bd", "#3b007b", "#39a3f4", "#e00000"],
      legend: {
        position: "bottom"
      },
      fill: {
        opacity: 1
      }
    }

    const {height="359"}=props;

    return (
      <React.Fragment>
        {!loading && <ReactApexChart
          options={options}
          series={series}
          // type={"heatmap"}
          type={graphType}
          height={height}
          className="apex-charts"
        />}
      </React.Fragment>
    );
}

export default StackedColumnChart
