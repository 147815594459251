import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { getRequest, queryParams } from "../../services/CoreService";
import { PAYMENT_MODULE } from "../../constants";
import { dateFormat, getShortName, toDecimal } from "utilities/TayomiUtil";
import {utils} from "helpers";

export default function TransactionsLatest(props){
    const [dataList, setDataList] = useState([]);
    const fetchData = () => {
        const paramsJson = {index: 1, page: 1, size: 6, order_by:"-id" };
        getRequest({url: (PAYMENT_MODULE + "transaction/" + queryParams(paramsJson))})
        .then(data => {
            const dataContent = data.results.map(d => ({
                ...d,
                to_account:d.to_account?.name,
                from_account: d.from_account?.name,
                is_paid: d.is_paid, 
                amount: utils.formatNumber(d.amount), 
                created: utils.formatDate(d.created)
            }));
            setDataList(dataContent);
        })
        .catch(error => {
            setDataList([]);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

  return (
    dataList?.length>0 && <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            Latest Transactions <Link to="/payments/transactions" className="btn-sm">View More<i className="mdi mdi-arrow-right ms-1"></i></Link>
          </CardTitle>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  {/* <th style={{ width: "20px" }}>
                    <div className="form-check font-size-16 align-middle">
                      <input type="checkbox" className="form-check-input" id="customCheck1" />
                      <label className="form-check-label" htmlFor="customCheck1">&nbsp;</label>
                    </div>
                  </th>
                  <th className="align-middle">Amount</th> */}
                  <th className="align-middle">Created At</th>
                  <th className="align-middle">Account From</th>
                  <th className="align-middle">Account To</th>
                  <th className="align-middle">Amount (TZS)</th>
                  <th className="align-middle">Is Paid</th>
                  
                  {/* <th className="align-middle">View Details</th> */}
                </tr>
              </thead>
              <tbody>
                {dataList.map((transaction, key) => (
                  <tr key={"_tr_" +transaction.id}>
                    {/* <td>
                      <div className="form-check font-size-16">
                        <input type="checkbox" className="form-check-input" id={transaction.id} />
                        <label className="form-check-label" htmlFor={transaction.id}>&nbsp;</label>
                      </div>
                    </td>
                    <td>{transaction.amount}</td> */}
                    <td>{transaction.created}</td>
                    <td>{transaction.from_account}</td>
                    <td>{transaction.to_account}</td>
                    <td>{transaction.amount}</td>
                    <td>
                      <Badge className={ "font-size-11 badge-soft-" + (transaction.is_paid? "success" : "warning") } color={transaction.is_paid ? "success" : "warning"} pill>{transaction.is_paid ? "Yes" : "No"}</Badge>
                    </td>
                    
                    {/* <td>
                      <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">View Details</Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

