import React from "react"
import Table from "components/Table";

export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function TransactionCategpries(props){
    return <Table 
            columns={columns}
            itemType = "payments.transactioncategory"
            itemType_verbose="Transaction Category"
    /> 
}