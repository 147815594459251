import { utils } from "./utils";
import { API_BASE_URL } from "../constants"
export const config = {
  API_URL: API_BASE_URL,
};
export const url = {
  BASE_URL: config.API_URL,
  //core
  user: "/api/v1/user/",
  group: "/api/v1/group/",
  contenttype: "/api/v1/contenttype/",
  permission: "/api/v1/permission/",
  branch: "/api/v1/branch/",
  branchtype: "/api/v1/branchtype/",
  membertype: "/api/v1/membertype/",
  attachment: "/api/v1/attachment/",
  region: "/api/v1/region/",
  district: "/api/v1/district/",
  member: "/api/v1/member/",
  department: "/api/v1/department/",
  certificate: "/api/v1/certificate/",
  church: "/api/v1/church/",
  leadership: "/api/v1/leadership/",
  grade: "/api/v1/grade/",
  zonal: "/api/v1/zonal/",
  denomination: "/api/v1/denomination/",
  // auth
  login: "/api/v1/auth/login/",
  logout: "/api/v1/auth/logout/",
  register: "/api/v1/auth/register/",
  authuser: "/api/v1/auth/user/",
  // pasword
  // extra
  tnc: "/tnc/",
  privacy: "/privacy/",
  //extra
  password: {
    reset: "/api/v1/auth/password/reset/" /*POST: email*/,
    resetConfirm: "/api/v1/auth/password/reset/confirm/",
    change: "/api/v1/auth/password/change/",
  },
  todo: {
    taskcategory: "/todo/api/v1/taskcategory/",
    task: "/todo/api/v1/task/"
  },
  payments: {
    account: "/payments/api/v1/account/",
    transaction: "/payments/api/v1/transaction/",
    transactioncategory: "/payments/api/v1/transactioncategory/",
    fee: "/payments/api/v1/fee/",
    payment: "/payments/api/v1/payment/",
  },
  getURL: (path, { item = { id: undefined }, type = "detail" } = {}, params = {}) => {
    const base = utils.getObject(url, path);
    let link = base;
    if (["detail", "delete", "edit", "view", "patch", "change"].includes(type)) {
      link = `${base}{id}/`;
    }
    return utils.stringify(params, {
      baseURL: utils.replaceVariablesFromString(link, item)
    });
  },
  routes: {
    dashboard: "/dashboard",
    profile: "/profile",
    login: "/login",
    register: "/register",
    logout: "/logout",
    contact: "/contact",
    faq: "/faq",
    user: "/user/{id}",
    tnc: "/terms",
    privacy: "/privacy",
    member: "/member/{id}",

    tasks: "/todo/tasks",
    task: "/todo/task/{id}",

    branchs: "/branchs",
    branch: "/branch/{id}",

    zonals: "/zonals",
    zonal: "/zonal/{id}",

    regions: "/regions",
    region: "/region/{id}",

    districts: "/districts",
    district: "/district/{id}",

    payments: "/payments/payments",
    payment: "/payments/payment/{id}",

    denominations: "/denominations/",
    denomination: "/denomination/{id}",

    reset: "/reset",
    grades: "/grades",
    get: (path, item) => {
      /*
       eg: url.routes.get("product", item:{id:12}) =>"/#/product/12"
      */
      const link = utils.getObject(url.routes, path);
      return utils.replaceVariablesFromString(link, item)
    }
  }
};
