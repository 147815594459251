import React from 'react';
import AddItem from "components/AddItem";
import {url, utils} from "helpers";

export default function Region(props){
    // const loggedUser = utils.getUser()
    const config ={
        itemType:"region",
        // permissionsTitle:"Share Request",
        columns:[
          {   
            type: "text", 
            id: "name", 
            name: "name", 
            title: "Region Name", 
            placeholder: "Enter Name", 
            offset: 1, 
            col:5
        },
        {   
          type: "text", 
          id: "code", 
          name: "code", 
          title: "Region Code", 
          placeholder: "Enter Code", 
          offset: 1, 
          col:5
      },
      {
        type: "select-filter",
        id: "zonal",
        name: "zonal",
        title: "Zonal",
        valueField: "zonal.id",
        optionsURL: url.zonal,
        formatBeforeSubmit: v => v.value,
        options: [],
        placeholder: "Choose Zonal",
        noOptionsMessage: "Search to add new zonal",
        append: document.body,
        offset: 1, 
        col:5
      },


        ].filter(d=>d)
    }
    return <AddItem {...props} config={config} />
}
