import React, { useState, useEffect, useCallback } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Button,
  // Label,
  CardSubtitle,
} from "reactstrap"
import PaginationComponent from "react-reactstrap-pagination";
import "assets/scss/datatables.scss"
import { useHistory } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import { ExportItems } from "utilities/TayomiUtil"
import _ from "lodash"
import moment from "moment";
// import "./PageableTable.scss"
export const PageableTable = props => {
  const { toggleAdvancedFilter } = props;
  const history = useHistory()
  const [isPaginated, setIsPaginated] = useState(props.isPaginated || false)
  const width = window.innerWidth;
  let maxPaginationNumbers = 1;
  if (width < 390) {
    maxPaginationNumbers = 1
  }
  else if (width < 520) {
    maxPaginationNumbers = 2
  } else {
    maxPaginationNumbers = parseInt(
      (width / 390) + 8
    )
  }

  useEffect(() => {
    setIsPaginated(props.isPaginated === undefined ? true : props.isPaginated)
  }, [props.isPaginated])

  const addItemClick = () => {
    history.push(props?.addItemLink || "#")
  }

  const canAdd = props.addItemLink;

  function updateSearch(e) {
    props?.setFilter({
      ...props.filter,
      [e.target.name]: e.target.value,
      page: undefined
    })
  }

  function clearFilters(e) {
    props?.setFilter({
      resetAll: true
    })
  }

  const onChangeSearch = useCallback(_.debounce(updateSearch, 1200), [])

  return (
    <>
      <Row className="mb-2">
        <Col className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center text-md-start text-lg-start">
          <Row>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-2">
              <div>
                <input
                  type="text"
                  className="form-control form-control-sm search-box mb-2 d-inline-block"
                  name="search"
                  value={props?.filter?.globalFilter}
                  onChange={onChangeSearch}
                  placeholder="Search..."
                />
                {/* <i className="bx bx-search-alt search-icon search-icon-sm" /> */}
              </div>
            </Col>
            {true && (
              <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-2">
                <Flatpickr
                  id="created_gte"
                  defaultValue={props?.filter?.created__gte}
                  value={props?.filter?.created__gte}
                  name="created_gte"
                  onChange={e => {
                    props?.setFilter({
                      ...props.filter,
                      created__gte: moment(e[0]).format(),
                      page: undefined
                    })
                  }}
                  className="form-control form-control-sm d-block"
                  placeholder="Start Date"
                />
              </Col>
            )}
            {true && (
              <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mt-2 mb-2">
                <Flatpickr
                  id="created_lte"
                  defaultValue={props?.filter?.created__lte}
                  value={props?.filter?.created__lte}
                  name="created_lte"
                  onChange={e =>
                    props?.setFilter({
                      ...props.filter,
                      created__lte: moment(e[0]).add(1, "days").format(),
                      page: undefined
                    })
                  }
                  className="form-control form-control-sm d-block"
                  placeholder="End Date"
                />
              </Col>
            )}
          </Row>
        </Col>
        {true && (
          <Col className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center text-md-end text-lg-end">
            <div className="float-sm-end float-md-end float-lg-end mt-0">
              {toggleAdvancedFilter && <>
                {true &&<div
                  onClick={clearFilters}
                  // color="primary"
                  className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1"
                >
                  Clear Filters
                </div>}
                <div
                  onClick={toggleAdvancedFilter}
                  // color="primary"
                  className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1"
                >
                  <i className="fa fa-filter" />
                </div>
              </>}
              {canAdd &&
                <Button
                  type="button"
                  onClick={() => addItemClick()}
                  color="primary"
                  className="btn btn-sm btn-rounded waves-effect waves-light mt-0 me-1"
                >
                  <i className="bx bx-plus me-2" />{" "}
                  {props.addItem !== null ? props.addItem : "Add Item"}
                </Button>}
              {props?.title && <ExportItems {...props} />}
            </div>
          </Col>
        )}
      </Row>
      {props?.dataList?.rows?.length > 0 && (
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <MDBDataTable
                small
                responsive
                striped
                bordered
                // autoWidth
                data={props.dataList}
                paging={false}
                searching={false}
                theadColor="ddw-primary"
                noBottomColumns={true}
              />
            </div>
          </Col>
        </Row>
      )}
      {props?.dataList?.rows?.length > 0 && isPaginated && (
        <Row className="justify-content-center">
          <Col lg="12">
            <center>
              <PaginationComponent
                totalItems={props.totalItems}
                pageSize={props.pageSize}
                onSelect={props.handlePageClick}
                maxPaginationNumbers={maxPaginationNumbers}
                defaultActivePage={props.pageIndex}
              />
            </center>
          </Col>
        </Row>
      )}
      {props?.dataList?.rows?.length <= 0 && (
        <>
          {props?.totalPages > 0 && (
            <CardSubtitle className="mb-3 text-center">
              There are no {props?.title?.toLowerCase() || "items"} for your
              search, widen your search
              {canAdd && <Button
                type="button"
                onClick={() => addItemClick()}
                color="white"
                className="btn btn-link btn-sm btn-rounded waves-effect waves-light mt-0 me-0"
              >
                {props?.addItem || "Add Item"}
              </Button>}
            </CardSubtitle>
          )}
        </>
      )}
    </>
  )
}
