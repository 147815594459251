import React from 'react';
import AddItem from "components/AddItem";
import {url, utils} from "helpers";

export default function Branch(props){
    const loggedUser = utils.getUser()
    const config ={
        itemType:"branch",
        itemType_verbose:"Branch",
        // permissionsTitle:"Share Request",
        columns:[
          {   
            type: "text", 
            id: "name", 
            name: "name", 
            title: "Name", 
            placeholder: "Enter Name", 
            offset: 1, 
            required:true,
            col:5
        },
        {   
          type: "text", 
          id: "code", 
          name: "code", 
          required:true,
          title: "Code", 
          placeholder: "Enter Code", 
          offset: 1, 
          col:5
      },
      {
            type: "select-filter",
            id: "region", 
            name: "region", 
            title: "Region", 
            options: [], 
            placeholder: "Choose region", 
            valueField:"district.region.id",
            optionsURL:url.region,
            getLabel:(item)=>`${item.name}`,
            noOptionsMessage: "Search Region", 
            
            append: document.body, 
            offset: 1, 
            col:5
        },
            {
                type: "select-filter",
                id: "district", 
                name: "district", 
                title: "District", 
                options: [], 
                placeholder: "Choose district", 
                valueField:"district.id",
                optionsURL:url.district,
                getLabel:(item)=>`${item.name}`,
                noOptionsMessage: "Add New District", 
                append: document.body, 
                getOptionsParams:(state)=>({region__id:state.region}),
                offset: 1, 
                col:5
            },
            {
                type: "select-filter",
                id: "member_type", 
                name: "member_type", 
                title: "Member Type", 
                options: [], 
                placeholder: "Choose Type", 
                valueField:"member_type.id",
                optionsURL:url.membertype,
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Type", 
                // onCreateOption: newRegionModal, 
                append: document.body, 
                offset: 1, 
                col:5
            },

            {
                type: "select-array",
                id: "branch_types", 
                name: "branch_types", 
                title: "Branch Types", 
                options: [], 
                placeholder: "Choose Types", 
                optionsURL:url.branchtype,
                setValue: (item) => item?.branch_types?.map(p => ({ value: p?.id, label: p?.name })),
                formatValueBeforeSubmit: (d, item) => {
                  const allIds = item?.branch_types?.map(i => i.id) || [];
                  const newIds = d?.map(d => d.value) || [];
                  const remove = _.difference(allIds, newIds)
                  if (newIds?.length === 0 || !newIds && remove) {
                    return undefined
                  }
                  return { "add": newIds, remove: item?.id ? remove : undefined }
                },
                // filterOption: regionFilter, 
                noOptionsMessage: "Add New Branch Type", 
                // onCreateOption: newRegionModal, 
                append: document.body, 
                offset: 1, 
                col:5
            }

        ].filter(d=>d)
    }
    return <AddItem {...props} config={config} />
}
