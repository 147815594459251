import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Members from "pages/Member/Members"
import Branchs from "pages/Branch/Branchs"
import Branch from "pages/Branch/Branch"
import Member from "pages/Member/Member"
import Transactions from "pages/Payment/Transactions"


import BranchTypes from "pages/Branch/BranchTypes"
import BranchType from "pages/Branch/BranchType"


import Regions from "pages/Branch/Regions"
import Region  from "pages/Branch/Region"
import Districts from "pages/Branch/Districts"
import  District  from "pages/Branch/District"
import Departments from "pages/Branch/Departments"
import Department from "pages/Branch/Department"
import MemberTypes from "pages/Member/MemberTypes"
import MemberType from "pages/Member/MemberType"
import TransactionCategories from "pages/Payment/TransactionCategories"
import { TransactionCategory } from "pages/Payment/TransactionCategory"
import Accounts from "pages/Payment/Accounts"
import Account from "pages/Payment/Account"
import Transaction from "pages/Payment/Transaction"
import { Permission } from "pages/User/Permission"
import Groups from "pages/User/Groups"
import Group from "pages/User/Group"
import Users from "pages/User/Users"
import { User } from "pages/User/User"
import Fees from "pages/Payment/Fees"
import { Fee } from "pages/Payment/Fee"
import TaskCategories from "pages/todo/TaskCategories"
import TaskCategory from "pages/todo/TaskCategory"
import Tasks from "pages/todo/Tasks"
import Task from "pages/todo/Task"
import Profile from "pages/User/Profile"
import Churches from "pages/Branch/Churches"
import Church from "pages/Branch/Church"
import Certificates from "pages/Member/Certificates"
import Certificate from "pages/Member/Certificate"

import Leaderships from "pages/Member/Leaderships"
import Leadership from "pages/Member/Leadership"

import Grades from "pages/Member/Grades"
import Grade from "pages/Member/Grade"

import Denominations from "pages/Core/Denominations"
import Denomination from "pages/Core/Denominations/Denomination"

import Zonals from "pages/Core/Zonals"
import Zonal from "pages/Core/Zonals/Zonal"

import Payments from "pages/Payment/Payments"
import Payment from "pages/Payment/Payment"
import TnC from "pages/tnc";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: Profile },

  { path: "/denominations", component: Denominations },
  { path: "/denomination/:dataId", component: Denomination },

  { path: "/grades", component: Grades },
  { path: "/grade/:dataId", component: Grade },

  { path: "/membertypes", component: MemberTypes },
  { path: "/membertype/:dataId", component: MemberType },
  { path: "/members", component: Members },
  { path: "/member/:dataId", component: Member },
  { path: "/certificates", component: Certificates },
  { path: "/leaderships", component: Leaderships },
  { path: "/leadership/:dataId", component: Leadership },
  { path: "/certificate/:dataId", component: Certificate },
  { path: "/permission/:dataId", component: Permission },
  { path: "/groups", component: Groups },
  { path: "/group/:dataId", component: Group },
  
  { path: "/users", component: Users },
  { path: "/user/:dataId", component: User },

  { path: "/zonals", component: Zonals },
  { path: "/zonal/:dataId", component: Zonal },

  { path: "/branchtypes", component: BranchTypes },
  { path: "/branchtype/:dataId", component: BranchType },

  { path: "/branchs", component: Branchs },
  { path: "/branch/:dataId", component: Branch },
  { path: "/departments", component: Departments },
  { path: "/department/:dataId", component: Department },
  { path: "/churchs", component: Churches },
  { path: "/church/:dataId", component: Church },
  { path: "/regions", component: Regions },
  { path: "/region/:dataId", component: Region },
  { path: "/districts", component: Districts },
  { path: "/district/:dataId", component: District },
  {
    path: "/payments/transactioncategorys",
    component: TransactionCategories,
  },
  {
    path: "/payments/transactioncategory/:dataId",
    component: TransactionCategory,
  },
  { path: "/payments/payments", component: Payments },
  { path: "/payments/payment/:dataId", component: Payment },
  { path: "/payments/accounts", component: Accounts },
  { path: "/payments/account/:dataId", component: Account },
  { path: "/payments/transactions", component: Transactions },
  { path: "/payments/transaction/:dataId", component: Transaction },
  { path: "/payments/fees", component: Fees },
  { path: "/payments/fee/:dataId", component: Fee },
  { path: "/todo/taskcategorys", component: TaskCategories },
  { path: "/todo/taskcategory/:dataId", component: TaskCategory },
  { path: "/todo/tasks", component: Tasks },
  { path: "/todo/task/:dataId", component: Task },
  
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/terms", component: TnC },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
