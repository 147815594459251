import React, { useEffect, useState } from "react"
import { TabPane, Button, Alert } from "reactstrap";
import { utils, url, requests } from "helpers";
import Member from "./Member";
import avatar1 from "../../assets/images/users/graduate.png"
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';

const MemberInfo = (props) => {
    const [loggedUser, setLoggedUser] = useState(utils.getUser());
    const member = loggedUser?.member;
    const [isEditing, setIsEditing] = useState(false);
    const [avatar, setAvatar] = useState(member?.profile_photo || avatar1)
    const [selectedFile, setSelectedFile] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null)


    async function uploadPhoto() {
        if (!isEditing) return setIsEditing(!isEditing)
        const formData = new FormData() 
        formData.append('profile_photo', selectedFile)
        const link = url.getURL("member", { type: "detail", item: member });
        const res = await requests.patch(
            link,
            formData,
        )
        setLoggedUser({
            ...loggedUser, 
            member:res
        })
        setAlertMessage({message:"Profile photo was updated successfully", type:"info"})
    }
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file)
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setAvatar(reader.result);
            });
            reader.readAsDataURL(file);
        }
    };

    return (
        <TabPane tabId="UserInfo">
            {!!member && <div className="text-center">
                <div className="avatar-md profile-user-wid mb-4">
                    <img height={140} src={avatar} alt="" className="rounded-circle" />
                </div>
                {!!alertMessage && <Alert type={alertMessage.type}>{alertMessage.message}</Alert>}
                {!isEditing ? <div onClick={uploadPhoto}>Update Profile Photo <i className="fa fa-edit"></i></div> :
                    <>
                        <input type="file" accept="image/*" onChange={onSelectFile} />
                        {/* <ReactCrop src={src} crop={crop} onChange={newCrop => setCrop(newCrop)} /> */}
                        {!!selectedFile && <Button onClick={uploadPhoto}>Submit</Button>}
                    </>}
            </div>}

            <Member nextPage={url.routes.dashboard} match={{ params: { dataId: loggedUser?.member_id || 0, displayElement: false } }} />
        </TabPane>
    )
}

export default MemberInfo;