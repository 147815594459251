import React, { useEffect, useState } from "react"
import Table from "components/Table";

export const columns = [
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 250,
    },
    {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
    },
];


export default function Grades(props){
    return <Table 
            columns={columns}
            itemType = "grade"
            itemType_verbose="Level of Study"
    /> 
}
